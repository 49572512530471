import axios from 'axios';
import { apiUrl} from'../Config/Config';

const membership_plan = () => {
    let planLists = [];
    axios.post(apiUrl+"donation/plantypes").then(res => {
        if(res.data.status == "success"){
            let i=0;
            let planlist = res.data.planlist;
            
            for(i; i<planlist.length; i++){
                planLists[i] = { value: planlist[i]['id'], label: planlist[i]['title'] };
            }
            
        }       
    });
    return planLists;
}

const categories = () => {
    let catelists = [];
    // axios.post(apiUrl+"faq/categories").then(res => {
    //     if(res.data.status == "success"){
    //         let i=0;
    //         let catelist = res.data.catelist;
            
    //         for(i; i<catelist.length; i++){
    //             catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
    //         }
            
    //     }       
    // });
    return catelists;
}

const categories1 = () => {
    var qs = require('qs');

    var postobject = {
        type: 'all'
    };

    let catelists = [];
    // axios.post(apiUrl+"faq/categories",qs.stringify(postobject)).then(res => {
    //     if(res.data.status == "success"){
    //         let i=0;
    //         let catelist = res.data.catelist;
            
    //         for(i; i<catelist.length; i++){
    //             catelists[i] = { value: catelist[i]['id'], label: catelist[i]['cate_name'] };
    //         }
            
    //     }       
    // });
    return catelists;
}

export const lang = {
    customer : {
        home :{
            find_btn: 'Find Your Genrun',
            find_probtn: 'Find Your GenPro',
            choose_services : 'Choose Your Services',
            banner_title: 'Find the best Run Errand for your needs',
            banner_protitle: 'Find the best Service Provider for your needs',
            popular_services : 'Popular Services',
            load_more_services: 'Load More Services',
            asktocall : 'Ask to Call',
        },
        trans: {
            genruncounterfee : 'GenRun Counter Fee',
            text_message : 'GenMessage',
            counter_offer:'Counter-offer',
        }
    },
    common: {
        title:'Student Charity Fund',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
        membership_plan_option: membership_plan(),
        categories_option: categories(),
        categories_option1: categories1(),
		image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
        expired: 'Expired',
        more_info: 'More Info',
        no_trans:'No transaction found',
        enter_amount : 'Enter Amount',
        send : 'Send',
        accept : 'Accept',
        gentrun: 'GenRun',
        genpro: 'GenPro',
        genproperty: 'GenProperty',
        login_type:[
            { value: 'supporters', label: 'Supporters' },
            { value: 'avrech', label: 'Avrech' },
            { value: 'kollel', label: 'Kollel' },
            { value: 'shule', label: 'Shule' },
            { value: 'companies', label: 'Companies' },
        ],
        field_options :[
            { value: 'campaign', label: 'Campaign' },
            { value: 'supporting', label: 'Supporting' },
            { value: 'supporters', label: 'Supporters' },
            { value: 'donation_plan', label: 'Donation plan' },
            { value: 'showpackagedetail', label: 'Package detail' },
            { value: 'my_special_request', label: 'My special request' },
            { value: 'special_request', label: 'Special request' },
            { value: 'addStory', label: 'Add Story' },
            { value: 'account_management', label: 'Account Management' },

            { value: 'invite_friends', label: 'Invite Friends' },
            { value: 'manage_avrech', label: 'Manage Avrech' },
        
            { value: 'my_support', label: 'My Support' },
            { value: 'avrech_support', label: 'Avrech Support' },

            
            { value: 'manage_supporters', label: 'Manage Supporters' },
            { value: 'manage_campaign', label: 'Manage Campaign' },
            { value: 'supporter_listing', label: 'Supporter Transactions' },

            { value: 'avrech', label: 'Avrech' },
            { value: 'kollel', label: 'Kollel' },
            { value: 'shules', label: 'Shules' },
        ],
        supporters_field_options :[
            { value: 'campaign', label: 'Campaign' },
            { value: 'supporting', label: 'Supporting' },
            { value: 'supporters', label: 'Supporters' },
            { value: 'donation_plan', label: 'Donation plan' },
            { value: 'showpackagedetail', label: 'Package detail' },
            { value: 'my_special_request', label: 'My special request' },
        ],
        shule_field_options :[
            { value: 'campaign', label: 'Campaign' },
            { value: 'supporter_listing', label: 'Supporter Transactions' },
            { value: 'manage_supporters', label: 'Manage Supporters' },
            { value: 'account_management', label: 'Account Management' },
            { value: 'campaign_supporting', label: 'Campaign Supporting' },
            { value: 'monthly_earning', label: 'Monthly Earning' },
            { value: 'yearly_earning', label: 'Yearly Earning' },
        ],
        kollel_field_options :[
            { value: 'invite_friends', label: 'Invite Friends' },
            { value: 'manage_avrech', label: 'Manage Avrech' },
            { value: 'account_management', label: 'Account Management' },
            { value: 'avrech_support', label: 'Avrech Support' },
            { value: 'my_support', label: 'My Support' },
            { value: 'monthly_earning', label: 'Monthly Earning' },
            { value: 'yearly_earning', label: 'Yearly Earning' },
        ],
        avrech_field_options :[
            { value: 'account_management', label: 'Account Management' },
            { value: 'invite_friends', label: 'Invite Friends' },
            { value: 'addStory', label: 'Add Story' },
            { value: 'supporters', label: 'Supporters' },
        ],
        companies_field_options :[
            { value: 'supporter_by_shules', label: 'Supporter By Shules' },
            { value: 'campaign', label: 'Campaign' },
            { value: 'yearly', label: 'Yearly Earning' },
            { value: 'monthly', label: 'Monthly Earning' },
            { value: 'avrech', label: 'Avrech' },
            { value: 'kollel', label: 'Kollel' },
            { value: 'shules', label: 'Shules' },
            { value: 'supporting', label: 'Supporting' },
            { value: 'supporters', label: 'Supporters' },
            { value: 'campaign_by_shules', label: 'Campaign By Shules' },
        ],add_internal_field_options :[
          
            { value: 'avrech', label: 'Avrech' },
            { value: 'kollel', label: 'Kollel' },
            { value: 'shules', label: 'Shules' },
            
        ],
        status_filter_option:[
            { value: 'Active', label: 'Active' },
            { value: 'Inactive', label: 'Inactive' },
        ],
        advertisement_linktype:[
            { value: 'internal', label: 'Internal' },
            { value: 'external', label: 'External' },
            { value: 'shules', label: 'Shules' },
            { value: 'offer', label: 'Offers' },
        ],
    },
    genrun : {
        cust_lead: {
            transaction_id: 'Transaction ID',
        }
    },
    info: {
        pro: {
            title:'How GenPro works?',
            heading:'Easy comparison for different professional service provider',
            slug:'how-it-works-genpro-mq',
            link:'https://geniehut.com/pro-detail'
        },
        run: {
            title:'How GenRun works?',
            heading:'Get a part timer (GenRun) to run errand for you',
            slug:'how-it-works-genrun-mq',
            link:''
        },
        property: {
            title:'How GenProperty works?',
            heading:'Advanced search engine retrieving desired results from different property search websites',
            slug:'welcome-to-genproperty-mq',
            link:''
        },
        ads: {
            title:'How GenAds works?',
            heading:'Barter GH points or items with other community users.',
            slug:'how-it-works-redeem-mq',
            link:''
        },
        how_geniehut_works_video: {
            title:'How Geniehut works?',
            heading:'',
            slug:'homepage-video-mq',
            link:''
        },
        point_summary: {
            title:'GH points summary',
            heading:'',
            slug:'gh-points-summary-popup-mq',
            link:''
        }
    },
    enable: {
         GP: {
            title:'Register as our service partners?',
            content:'Add services/listings and update the details to get 300 GH Points!',   
        },
        GR: {
            title:'Register as our service partners?',
            content:'Add services/listings and update the details to get 300 GH Points!',
        }
    },
    search: {
        subtitle: 'Enter the Subtitle',
    }
};


