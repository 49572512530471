import React, { Component } from 'react';
import { Link, } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const tutorid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      tutorid: tutorid,
      tutorlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :tutorid
    };
    axios.post(apiUrl+"tutor/view",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
        
          this.setState({tutorlistview:res.data.tutorlistview[0]}) 
        
      }	
		});
  }

   
   componentDidMount() {
     document.title = PageTitle('student View');
   }

   componentDidUpdate(prevProps,prevState){

   }
 

  
  render() {

    if((this.state.tutorlistview.u_name !== '') && (this.state.tutorlistview.u_name !== null)){
                var u_name = this.state.tutorlistview.u_name;
              }else{
                  u_name = 'N/A';
              }

              if((this.state.tutorlistview.f_name != '') && (this.state.tutorlistview.f_name != null)){
                var f_name = this.state.tutorlistview.f_name;
              }else{
                  f_name = 'N/A';
              }

              if(this.state.tutorlistview.kollel_name !== '' && this.state.tutorlistview.kollel_name !== null){
                var kollel_name = this.state.tutorlistview.kollel_name;
              }else{
                  kollel_name = 'N/A';
              }

              if((this.state.tutorlistview.about_student_story !== '') && (this.state.tutorlistview.about_student_story !== null)){
                var about_student_story = this.state.tutorlistview.about_student_story;
              }else{
                  about_student_story = 'N/A';
              }

              if((this.state.tutorlistview.supporting_amount !== '') && (this.state.tutorlistview.supporting_amount !== null)){
                var amount = this.state.tutorlistview.supporting_amount;
              }else{
                  amount = 'N/A';
              }


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="student" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Melamed View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.tutor_name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>UserName<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.username}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Age<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.age}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>No of kids<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.no_kids !=null ? this.state.tutorlistview.no_kids : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Learning Hours<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.learning_hours !=null ? this.state.tutorlistview.learning_hours : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.email}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.mobileno}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Location<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.tutor_location !=null ? this.state.tutorlistview.tutor_location : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Donation Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{amount}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Fund Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{f_name}</p>
                        </div>
                      </div>
                      {/*<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Plugin Id<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.plugin_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Domain Id<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.domain_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Address<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.tutorlistview.address}</p>
                        </div>
                      </div> */}
                  </div>
                </div>
                    
                  

                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default withRouter(View);