import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const packageId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      packageId: packageId,
      packagelistdata:'',
      studentlist:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :packageId
    };

    axios.post(apiUrl+"package/view",qs.stringify(postObject)).then(res => {
      if(res.data.status === 'success'){
          this.setState({ packagelistdata: res.data.packagelistview[0],
            studentlist:res.data.packagelistview.studentlist});

      }
    }); 
  }

   componentDidMount() {
     document.title = PageTitle('package View');
   }

      
avrechlist() {
  var avrechlist = this.state.studentlist;
    if (avrechlist != "undefined" && avrechlist != null) {      
    
        if (Object.keys(avrechlist).length > 0) {

          const helperlistDetails = avrechlist.map(
            (avrechlist, Index) => {
              let sno = Index+1;
              let comma;
              // if(Object.keys(avrechlist).length>sno) 
              // {
              //     comma=', ';
              // }else{
              //     comma=' ';
              // }
              return (
                  <span>{avrechlist.label}, </span>
              );
            }
          );
          return helperlistDetails;
        
      } else {
        return (
          <div>No Avrech</div>
        );
      }
    }else {
      return (          
          <div>No Avrech</div>
      );
    }
  }


  render() {
  if(this.state.packagelistdata!==undefined){
    var packagelistdata = this.state.packagelistdata;
    var descNewStr = "";
    if(packagelistdata.description){
      var htmlStr = packagelistdata.description;
      descNewStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
      descNewStr = descNewStr.replace(/&nbsp;/ig, '');
    }
  }
  
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="package" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Package Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Package<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{packagelistdata.package}</p>
                        </div>
                      </div>

                     
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Description<span>:</span> </b></p>
                        </div>
                       
                        <div className="view-details-two">
                            <p>{descNewStr}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>${packagelistdata.price}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Payment Type<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(packagelistdata.payment_type ? packagelistdata.payment_type  : "")}</p>
                        </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(packagelistdata.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                     
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Avrechs<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            {this.avrechlist()}
                        </div>
                      </div>

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default withRouter(View);