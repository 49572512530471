/* eslint-disable */
import React, { Component } from 'react';
import $ from 'jquery';
import {useNavigate} from 'react-router-dom';
import { apiUrl } from "../Config/Config";
import axios from 'axios';
/* import { GET_LOGINDATA} from '../../actions'; */
export const  withNavigation = (Component : Component) => {
	return props => <Component {...props} navigate={useNavigate()} />;
  } 
class Login extends Component {

	constructor(props) {
		
		super(props);
		
		this.state = {	
			user_name: '',
			password: '',		
			Loading: false,
			statusmessage:'',
			yeshiva_checked:false,
			kollel_checked:false,
			yeshiva:'',
			kollel:''
		};

    this.handleInputChange  = this.handleInputChange.bind(this);

	}

	componentDidMount(){
		let body = document.querySelectorAll("body")[0];
		body.classList.add("body-theme");
	}


  handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
   }
	handleFormSubmit = () => {

			if(this.validateForm()){

				this.setState({Loading:true});
				const formPayload = this.state;

				var login_as = "admin";
				
				if(login_as  === 'admin'){

					var qs = require('qs');

					var postObject = {
					"user_name": formPayload.user_name,
					"password": formPayload.password,
					};
					$(".login_submit").addClass('loading loading_data');

					axios.post(apiUrl+'adminpanel/adminlogin',qs.stringify(postObject))
					.then(res => {
					  if(res.data.status === "success"){
						this.setState({ Loading: false });
	                 	$(".login_submit").removeClass('loading loading_data');
						localStorage.setItem('loggedas','admin');
						localStorage.setItem('admin_id',res.data.te_admin_id);
						localStorage.setItem('admin_name',res.data.te_admin_name);
						localStorage.setItem('admin_company_name',res.data.admin_company_name);
						
						this.props.navigate('/setting');
						
						
					  }else{
						this.setState({statusmessage: res.data.message});
						setTimeout(
						   function() {
							   this.setState({statusmessage:''})
						   }
						   .bind(this),
						   3000
					   );	
					  }

					});
					//this.props.getLoginData(qs.stringify(postObject));

					

				}
				

			
				
			}

	}


	validateForm() {
		const {user_name,password} = this.state;
		let errors = {};
      	let formIsValid = true;
		$('.errorusername').html('');
		if (!user_name) {
		
			formIsValid = false;
			$('.errorusername').html('<span class="errorspan">Please check username</span>');
		}else if(user_name){		
			$('.errorusername').html('');
		}
		
		if (!password) {
			formIsValid = false;
			$('.errorpassword').html('<span class="errorspan">Please enter your password</span>');
		}else if(password){
			$('.errorpassword').html('');
		}
		this.setState({
			errors: errors
		});
		$(".login_submit").removeClass('loading loading_data');
		return formIsValid;
    }

render() {
	return (
		<div className="login-wrapper">
			<div className="account-wrapper">
				<div className="account-title-warpper">
				{/* <img src={loginImg} /> */}
					<h2 className="title"><span>Admin panel</span></h2>
					<div><h2>{this.state.statusmessage}</h2></div>
				</div>
				<div className="login-form">
				<form className="login" id="login" onSubmit={ (e) => { e.preventDefault(); this.handleFormSubmit(this); }}>

					    
						<input onChange={this.handleInputChange} type="checkbox"  name="kollel" checked={this.state.kollel_checked}/>
						<span>As a kollel</span>

						<div className="form-group">
							{/*<label>Username</label>*/}
									<input type="text" className="form-control round" name="user_name" id="user_name" value={this.state.user_name} onChange={this.handleInputChange}placeholder="Your Username" />
							<div className="errorusername"></div>
						</div>
						<div className="form-group">
							{/*<label>Password</label>*/}
							<input type="password" className="form-control round" name="password" id="password" value={this.state.password} onChange={this.handleInputChange} placeholder="Your Password" autoComplete="on"/>
							<div className="errorpassword"></div>
						</div>
						<div className="account-forgot-psw">
							<a href="/forget_password">Forgot Password?</a>
						</div>
						<div className="account-submit">
						{/* <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="button" onClick={this.handleFormSubmit.bind(this)}>
							{this.state.loading ===true &&
							<span class="load-data">Loading</span> 
							}
							Login
						</button> */}
						<button className="btn btn_orange btn_minwid login_submit animate-btn2 theme-btn" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Loading</span>:<span>Login</span>
                                        } 
								</button>	
						</div>
					</form>
				</div>
			</div>
		</div>

    );


}
}

export default withNavigation(Login);

