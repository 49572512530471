import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
// import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import moment from 'moment';
import { apiUrl,} from'../Config/Config';
import axios from 'axios';

class List extends Component {  
  constructor(props)
  {
    super(props);
    this.state = {
      kollellist:''
    };    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };
  
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

      axios.post(apiUrl+"kollel/list",qs.stringify(postobject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, kollellist: res.data});
      });

    }

   
    componentDidMount() {
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id
     };
     axios.post(apiUrl+"kollel/list",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, kollellist: res.data});
    });

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id         
  };
  axios.post(apiUrl+"kollel/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, kollellist: res.data});
  });

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.kollellist).length > 0) {
      if (Props.kollellist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.kollellist[0].records_count, kollellist: Props.kollellist[0]["kollellist"] });
        this.kollellist();
      }
    }

    if (Object.keys(Props.kolleldelete).length > 0) {
      if (Props.kolleldelete[0]["status"] === "success") {
        this.setState({
          kollellist: Props.kolleldelete[0]["kollellist"],
        });
      }

      this.setState({statusmessage: Props.kolleldelete[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
     window.location.reload(); 
    }
  }

  deletekollel(id){
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        id  :id
      };
      axios.post(apiUrl+"kollel/delete",qs.stringify(postObject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, kollellist: res.data});
      });
  }
kollellist() {
    var kollellist = this.state.kollellist;
    if (kollellist != "undefined" && kollellist != null) {
      if (kollellist.status == "success") {
        if (Object.keys(kollellist).length > 0) {
          const helperlistDetails = kollellist.kollellist.map(
            (kollellist, Index) => {
              let sno = Index+1;
              var istyle = { width: 100};
              return (
                <tr key={kollellist.id}>
                <td>{sno}</td>
                <td>{kollellist.username}</td>
                {localStorage.getItem('admin_id') === '1' && <td>{kollellist.org_password}</td> }
                <td>{kollellist.kollel_name}</td>
                <td>{kollellist.email}</td>
                <td>{kollellist.mobileno}</td>
                <td>{kollellist.domain_name !='' && kollellist.domain_name !=null ? kollellist.domain_name : 'N/A'}</td>
                <td>{kollellist.kollel_location}</td>
                <td>{moment(kollellist.created_on).format("MM/DD/YYYY hh:mm A")}</td>
                {/* <td>{kollellist.domain_name}</td> */}               
                <td className="actiongroup">
                    <Link to={{ pathname: '/kollel-view/'+kollellist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/kollel-edit/'+kollellist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.deletekollel.bind(this,kollellist.id)} className="" title="Duplicate">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="10" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="10" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }

  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="kollel" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">
                <div className="title">
                  <h3>Kollel</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                <div className="add_button">  
                    <a className="add" href='/kollel-add'>Add</a>
                </div>  
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row table-avrech">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
                  <th>Username</th>
                  {localStorage.getItem('admin_id') === '1' && <th>Password</th> }
			            <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Domain</th>
                  <th>Location</th>
                  <th>Registered On</th>
                 {/* <th>Domain</th> */}
                  <th>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.kollellist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;