import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Pagination from "react-js-pagination";
import $ from 'jquery';
import { apiUrl,} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      compaignlist:'',
      domainlist:[],
      companylist:[],
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_domain:'',
    search_university:'',
    search_company: '',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
      axios.post(apiUrl+"compaign/list",qs.stringify(postobject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
      });
    }

    checkstatus(id, status){
      let stat = ''
      if(status == 'Inactive'){
        stat = "A";
      }else{
        stat = "I";
      }
      $('.statusmessage').html('<span>Please wait...</span>');
      var qs = require('qs');
      var postObject = {
        admin_id : localStorage.getItem("admin_id"),
        loggedas : localStorage.getItem("loggedas"),
        compaign_id  :id,
        status: stat
      };

      axios.post(apiUrl+"compaign/change_status_compaign",qs.stringify(postObject)).then(res => {
        this.setState({ totalRecord : res.data.records_count, studentlist: res.data},()=>{
          $('.statusmessage').html('');
        });
      });

      setTimeout(()=>{
        var admin_id = localStorage.getItem("admin_id");
        var loggedas = localStorage.getItem("loggedas");
        var postobject1 = {
          activePage: 1,
          search_all: '',
          search_domain:'',
          search_university:'',
          search_company: '',
          admin_id: admin_id,
          loggedas: loggedas
        };
        axios.post(apiUrl+"compaign/list",qs.stringify(postobject1)).then(res => {
          this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
        });
        $('.statusmessage').html('');
      },1000);
    }

    inactivatecompaign(id, status){
      confirmAlert({
       customUI: ({ onClose }) => {
         return (
           <div className='custom-ui'>
             <h1>Are you sure, Do you want to inactive the account?</h1>
             <button onClick={onClose}>No</button>
             <button
               onClick={() => {
                  this.checkstatus(id, status)
                  onClose();
               }}
             >
               Yes
             </button>
           </div>
         );
       }
     });
     }

     activatecompaign(id, status){
     confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure, Do you want to active the account?</h1>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                this.checkstatus(id, status);
                onClose();
              }}
            >
              Yes
            </button>
          </div>
        );
      }
    });
    }

    componentDidMount() {

      var admin_id = localStorage.getItem('admin_id');
			axios.get(apiUrl+'student/getcompanylist?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){

					console.log(res.data);
				
					this.setState({
						companylist: [{value: '', label: 'Sort by Company'}].concat(res.data.companylist)
					})
				
				}else{
				//console.log("test")
				}
			});

   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_domain: this.state.search_domain,
       search_university: this.state.search_university,
       search_company : this.state.search_company,
       admin_id: admin_id,
       loggedas:loggedas
     };
     axios.post(apiUrl+"compaign/list",qs.stringify(postobject)).then(res => {
      this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
    });
   }

   handleInputChange = (event) => {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_domain : formPayload.search_domain,
    search_university : formPayload.search_university,
    search_company: formPayload.search_company,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"compaign/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
  });

} 



   componentWillReceiveProps(Props){

    console.log(Props);

    if (Object.keys(Props.compaignlist).length > 0) {
      if (Props.compaignlist[0]["status"] === "success") {
        this.setState({ totalRecord : Props.compaignlist[0].records_count, compaignlist: Props.compaignlist[0]["compaignlist"] });
        this.compaignlist();
      }
    }


    if(Props.changestatuscompaign !== this.props.changestatuscompaign){
      if (Props.changestatuscompaign[0]["status"] === "success") { 
            var qs = require('qs');
            var admin_id = localStorage.getItem("admin_id");
            var loggedas = localStorage.getItem("loggedas");
            var postobject = {
            activePage: 1,
            search_all: '',
            search_domain:'',
            search_university:'',
            search_company: '',
            admin_id: admin_id,
            loggedas: loggedas
            };
            axios.post(apiUrl+"compaign/list",qs.stringify(postobject)).then(res => {
              this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
            });
            this.setState({
             //  totalRecord : Props.changestatuscompaign[0].records_count, compaignlist: Props.changestatuscompaign[0]["compaignlist"] 
            });
           
            
            $('.statusmessage').html('Status Changed Successfully');
            window.location.reload(); 
           this.compaignlist();
      }
    }

    if (Object.keys(Props.deletecompaign).length > 0) {
      if (Props.deletecompaign[0]["status"] === "success") {
        this.setState({
          compaignlist: Props.deletecompaign[0]["compaignlist"],
        });
      }

      this.setState({statusmessage: Props.deletecompaign[0].message});

            setTimeout(
            function() {
              this.setState({statusmessage:''})
            }
            .bind(this),
            3000
          );
     window.location.reload(); 
    }
     
   }

   confirmdeletecompaign(id){
    confirmAlert({
     customUI: ({ onClose }) => {
       return (
         <div className='custom-ui'>
           <h1>Are you sure, Do you want to delete the account?</h1>
           <button onClick={onClose}>No</button>
           <button
             onClick={() => {
                this.deletecompaign(id)
                onClose();
             }}
           >
             Yes
           </button>
         </div>
       );
     }
   });
   }

   deletecompaign(id){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id
    };
   
    axios.post(apiUrl+"compaign/delete",qs.stringify(postObject)).then(res => {

    });
    setTimeout(()=>{
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject1 = {
        activePage: 1,
        search_all: '',
        search_domain:'',
        search_university:'',
        search_company: '',
        admin_id: admin_id,
        loggedas: loggedas
      };
      axios.post(apiUrl+"compaign/list",qs.stringify(postobject1)).then(res => {
        this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
      });
      $('.statusmessage').html('');
    },1000);
  }

handleClickSelecet = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");

  var postObject = {             
    activePage   : 1,
    search_domain : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };

  axios.post(apiUrl+"compaign/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
  });
  this.setState({
      search_domain: key,
      Loading:true     
  })

}

handleClickSelecetUniversity = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_university : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"compaign/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
  });
  this.setState({
      Loading:true     
  })

}

handleClickSelecetCompany = (event) => {

  const selectedIndex = event.target.options.selectedIndex;
  var key = event.target.options[selectedIndex].getAttribute('data-key');

  var qs = require('qs');
  var admin_id = localStorage.getItem("admin_id");
  var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_company : key,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"compaign/list",qs.stringify(postObject)).then(res => {
    this.setState({ totalRecord : res.data.records_count, compaignlist: res.data});
  });
  this.setState({
      Loading:true     
  })

}

compaignlist() {
    var compaignlist = this.state.compaignlist;
    if (compaignlist != "undefined" && compaignlist != null) {
      if (compaignlist.status == "success") {
        if (Object.keys(compaignlist).length > 0) {
          const helperlistDetails = compaignlist.compaignlist.map(
            (compaignlist, Index) => {
              let sno = Index+1;
              if((compaignlist.name !== '') && (compaignlist.name !== null)){
                var compaign_name = compaignlist.compaign_name;
              }else{
                compaign_name = 'N/A';
              }

              if(compaignlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var activebtn = 'no';
              if(localStorage.getItem('loggedas') === 'admin'){
                var activebtn = 'yes';
              }

              var istyle = { width: 100};
              console.log(typeof compaignlist.goal_amount,"compaignlist.goal_amount");
              return (
                <tr key={compaignlist.id}>
      					<td>{sno}</td>  
      					<td>{compaign_name}</td>
                <td>{compaignlist.email !== '' && compaignlist.email !== null ? compaignlist.email : 'N/A'}</td>
                <td>{compaignlist.mobileno !== '' && compaignlist.mobileno !== null ? compaignlist.mobileno : 'N/A'}</td>
                <td>${compaignlist.goal_limit_amount !== null && compaignlist.goal_limit_amount !== '' && compaignlist.goal_limit_amount !== '0' ? compaignlist.goal_limit_amount : '0'}</td>
                <td>${compaignlist.goal_amount !== null && compaignlist.goal_amount !== '' && compaignlist.goal_amount !== '0' ? compaignlist.goal_amount : '0'}</td>
                <td>${compaignlist.achieved_amount !== null && compaignlist.achieved_amount !== '' && compaignlist.achieved_amount !== '0' ? compaignlist.achieved_amount : '0'}</td>
                <td>{moment(compaignlist.created_on).format("MM/DD/YYYY hh:mm A")}</td>
                <td>{status}</td>                
                <td className="actiongroup" >
                    
                    {(localStorage.getItem('loggedas') === 'admin') ?
                      <> 
                    <Link to={{ pathname: '/compaign-view/'+compaignlist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/compaign-edit/'+compaignlist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.confirmdeletecompaign.bind(this, compaignlist.id)} className="" title="Delete">  <i className="fa fa-trash" aria-hidden="true"></i></Link>
                     </>:<></>
                    }

                    {(activebtn ==='yes')?
                      <>
                        {(status == 'Active') ?<Link onClick={this.inactivatecompaign.bind(this, compaignlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activatecompaign.bind(this, compaignlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>} 
                        </>:<>
                      </>
                    }
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

	  var current = this;
    var admin_id = localStorage.getItem('admin_id');

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="compaign" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
              <div className="listing-header">
                <div className="title">
                  <h3>Campaign</h3>
                </div>
               <div className="select-1">
              </div>
              <div className="select-2">
                 {admin_id == '1'?	
              <div className="form-group">
                <select  onChange={current.handleClickSelecetCompany}  value={this.state.search_company}>                    
                {this.state.companylist.map((companylist) => <option key={companylist.value} data-key={companylist.value} >{companylist.label}</option>)}
                </select>
              <div className="errorassign_comapny"></div>
              </div> : ''}
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                {localStorage.getItem('loggedas') === 'admin' && <div className="add_button">  
                    <a className="add" href='/compaign-add'>Add</a>
                </div>}
                {/*localStorage.getItem('loggedas') === 'kollel' && <div className="add_button">  
                    <a className="add" href='/compaign-add'>Add</a> &nbsp;|&nbsp;
                    <a className="" href='/compaign-import'>Import <i className="import-icon icon" aria-hidden="true"></i></a>
                  </div>*/}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Campaign Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Goal Amount</th>
                  <th>Expected Goal</th>
                  <th>Acheived Amount</th>
                  <th>Created On</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.compaignlist()}</tbody>
                </Table>
				</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;