import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { cmscontentURL } from '../Config/Config';
// import CKEditor from 'ckeditor4-react';
import MyEditor from './MyEditor';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { Link,useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const cmsId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
			name:'',
			cms_id:'',
			logo_content:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
            image: [],
			cms_image_name: '',
			cms_image_preview: '',
			isHidden: false,
			cmsId: cmsId,
			Loading:false,
			fileName: [],
			title:'',
			thumbDefault: '',
			thumbtypeimg: [],	
			admin_id: localStorage.getItem('admin_id'),
			button_text:'',
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.onChangeHandler = this.onChangeHandler.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.removeImage = this.removeImage.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );

	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.removeImagebyindex = this.removeImagebyindex.bind(this);

	   axios.get(apiUrl+"cms/cmsdetail?id="+cmsId).then(res => {
			if(res.data.status === "success"){
				const formdata = res.data.cmslist;						
				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({name:formdata.name,button_text:formdata.button_text});
				
				var callfunc = this;
				setTimeout(function () {
				callfunc.setState({logo_content:formdata.landing_content});
				}, 1000);
				this.setState({ tabgalleries: formdata.tabimageslist }, () => {
					
					// Render your galleries here using this.state.tabgalleries
				});

				//this.setState({logo_content:formdata.landing_content});
				this.setState({cms_id: formdata.id, title: formdata.title});
				this.setState({cms_image_preview: formdata.landing_logo});

			  

			}	
		});
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
     onEditorChange( evt ) {
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            // logo_content: evt.editor.getData()
            logo_content: evt
        } );
		}, 1000);
     
    }
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

	componentDidMount() {
		document.title = PageTitle('CMS Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.navigate.push('/');
		}
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value === ''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }
      let vehicletruck = selectedVehicle.label;
      let lowercasetext = vehicletruck.toLowerCase();
      if(lowercasetext == 'truck'){
      	this.setState({enableweight: true})
      }else{
      	this.setState({enableweight: false, truck_weight:0})
      }
   }

  onChangeHandler=event=>{
    let reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {		
		
      this.setState({
        cms_image_name: file.name,
        cms_image_preview: reader.result,
        image: file
      });
	 
		
    };

    reader.readAsDataURL(file);
  }
  

onThumbChanged = (e) => {	
    this.setState({
      thumbDefault: e.currentTarget.value
      });
	  
  }
 

 handleFormSubmit = () => {

			if(this.validateForm()){

				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					id : formPayload.cms_id,
					name: formPayload.name,
					title: formPayload.title,
					logo_content:formPayload.logo_content,
					cms_image   : formPayload.image,
					status      :status,
					button_text:formPayload.button_text

					
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       

				var filesdata = this.state.tab_image;			
				if(filesdata){
					for (var i in filesdata) {
						formData.append('multiple_images[]',filesdata[i])
					}
				}

			    // this.props.getEditCms(formData,config);
				axios.post(apiUrl+"cms/edit",qs.stringify(postObject)).then(res => {
					if(res.data.status === 'success'){

						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');						
						

						setTimeout(
						function(){							
							$('.success_message').html('');
							// const navigate = this.props.navigate;
							this.props.navigate('/cms');
							}
						.bind(this),
						3000
						);
					}else{
					this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		const {name,logo_content,cms_image_preview} = this.state;
		let errors = 0;
	
		if (!name) {
			errors++;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

  removeImage = () => {
  	 this.setState({cms_image_preview:''})	  
  }
  uploadMultipleFiles = (e) =>{
	this.fileObj = [];

		this.fileObj.push(e.target.files)
		for (let i = 0; i < this.fileObj[0].length; i++) {
			this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
			//this.fileArray.push(this.fileObj[0][i]
			this.imageArray.push(this.fileObj[0][i])

		}
		
		this.setState({ tab_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	}
	removeImagebyindex = (indexvalue) => {
	   
		var list = [...this.imageArray];
		this.imageArray = [];
		this.fileArray = [];
		list.splice(indexvalue, 1);
	   
		for (let i = 0; i < list.length; i++) {
		  this.imageArray.push(list[i]);
		  this.fileArray.push(URL.createObjectURL(list[i]));
		}
	  
		  this.setState({ tab_image: this.imageArray })	
  }
  confirmPopup = (id,imagepath,selected_image,type) => {
	this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image, type: type})	
	$('.confirm-action').addClass('show');		
}
deleteimage(){
	var qs = require('qs');
	var postObject = {
	  admin_id : localStorage.getItem("admin_id"),
	  gallery_id  :this.state.selectid,
	  id  :this.state.tabId,
	  imagepath  :this.state.imagepath,
	  selected_image  :this.state.selected_image,
	  type: this.state.type
	};
/* 	axios.post(apiUrl+"tabmanagement/deleteimage",qs.stringify(postObject)).then(res => {      
		  if(res.data.status === 'success'){
			if(this.state.type == 'product'){
				this.setState({productgalleries:res.data.productgalleries})
			}else{
			}
			this.setState({ tabgalleries: res.data.productgalleries }, () => {
				// Render your galleries here using this.state.tabgalleries
			});
			$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
			setTimeout(function() {
				$('.success_message').html('');   
			}.bind(this),3000);
		  }
	});  */
}
Confirmstatus = (confirmstatus) => {
	if(confirmstatus == 'yes'){
	  this.deleteimage();
	  $('.confirm-action').removeClass('show');
	}else{
	  $('.confirm-action').removeClass('show');	
	}  
}
	
  render() {

	let carImg = '';
	let preview = '';
	
	/*if (user_image_preview!== null && user_image_preview!== '') {
	    carImg = carImageUrl + "/" + user_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}*/

	
  	const {selectedOption,cms_image_preview} = this.state;

	if (cms_image_preview!== null && cms_image_preview!== '') {
	    carImg = cmscontentURL + "/" + cms_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}
	
	
	let imageslist = [];

	  let indexlength =  imageslist.length;

	const gallerylocal =  this.fileArray && this.fileArray.map((url,index) => (
		<li className="thumb" key={indexlength+index}  >
			<span><img src={url}  alt="..." /></span>
			<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
		</li>
	))

	let loaderimg = '';
	var current = this;
	

	const imagepre = this.state.image_preview;

	if (this.state.tabid!=='' && this.state.imagename!== null && this.state.imagename!== '' && this.state.imagename!== undefined ) {
		loaderimg = cmscontentURL + '/'+this.state.imagename;
		preview = <img className="img_class" src={loaderimg} alt="" />;
	// alert('hh');
	}else{
		preview = <img className="img_class" src={imagepre} alt="" />;
	}
   
	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="bus" />  

	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label> Name:</label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
					<div className="errorname"></div>
				</div>

				<div className="form-group">					
  					<label>Tab Content:</label>
 				     {/* <CKEditor
					data={this.state.logo_content}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					 <MyEditor initialData={this.state.logo_content} onEditorChange={this.onEditorChange}> </MyEditor>

  					<div className="errorlogo_content"></div>
  				</div>
				
				<div className="form-group">
					<label>Upload Mulitiple Images (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} ></span>:</label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
					</div>
					<ul className="carimagelisting">
										{/*imageslist*/}
									{gallerylocal}
									</ul>
					{/* {image_preview?<a href="javascript:void(0);">X</a>:''} */}
					
				</div>
				<div className="form-row">
									{/* <div className="form-left"> */}
										{this.state.tabgalleries!=''?
										<div className="form-group">
											<div className='gallery-main'>
												<label>Image Galleries</label>
												<div className='gallery-container'>
													{this.state.tabgalleries?.map((item) => (
													<div className='gallery-inner' key={item.id}>
														<img width="100px" height="100px" src={cmscontentURL+'/'+'tabimages'+'/'+item.tab_image} alt={`Image ${item.id}`}  />
														 <Link onClick={this.confirmPopup.bind(this, item.id,item.tab_image_path,item.tab_image,'tab')} className="" title="Delete">X</Link>
													</div>
													))}
												</div>
											</div>
										</div>:''}		
									{/* </div> */}
								</div>
			</div>
			<div className="form-right">
			   <div className="form-group">
					<label> Title:</label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title} />
					<div className="errortitle"></div>
				</div>
				<div className="form-group">
					<label> Button title:</label>
				    <input type="text" name="button_text" onChange={this.handleInputChange} className="form-control" value={this.state.button_text} />
					<div className="errorname"></div>
				</div>
				<div className="form-group">
			    <label>Tab Icon:</label>
				<div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
                </div>
                {preview}
                {/*cms_image_preview?<a href="javascript:void(0);" onClick={this.removeImage}>X</a>:''*/}
                </div>

			
			

				

				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	</div>
    </div>
    );
  }
}
export default withRouter(Edit);