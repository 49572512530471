import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from 'ckeditor4-react';
import MyEditor from './MyEditor';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			membership_name:'',
			description:'',
			amount:'',
			period:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedPlanOption: '',
			selectedvalue:'active',
			selectedPlanvalue:'',
			planLists:''
			
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );

    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            // description: evt.editor.getData()
            description: evt
        } );
		}, 1000);
     
    }

	componentDidMount() {
	
	console.log("component did mount")
	}
	  
// 	handleChange = selectedOption => {
//         this.setState({selectedOption})
//         this.setState({  selectedvalue : selectedOption.value});
//    };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'ins_cate'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
		}
		console.log(selOption.value, 'sdfg')
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => {
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status, cate = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

	if(formdata.selectedPlanvalue === '' && formdata.selectedPlanvalue!== 0){
		cate =formdata.selectedPlanOption.value;
	}else{
		cate = formdata.selectedPlanvalue;
	}

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		membership_name : formdata.membership_name,
		description:formdata.description,
		amount: formdata.amount,
		period: formdata.period,
		status: status,
		cate_id:cate
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}
	axios.post(apiUrl+"donation/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/donation')
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const {membership_name,description,amount, period, selectedPlanOption} = this.state;

	let errors = {};
	  let formIsValid = true;

	  if(!membership_name){
		formIsValid = false;
		$('.errormembership_name').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errormembership_name').html('');
	}

	if(!description){
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errordescription').html('');
	}
	
	if(!amount){
		formIsValid = false;
		$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.erroramount').html('');
	}

	if(!period){
		formIsValid = false;
		$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorperiod').html('');
	}

	if(!selectedPlanOption){
		formIsValid = false;
		$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorplan').html('');
	}
		
	this.setState({
		errors: errors
	});
	return formIsValid;
}



  render() {
	const {selectedOption,selectedPlanOption} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="donation" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Donation</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Amount ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount}/>
					<div className="erroramount"></div>
				</div>
				<div className="form-group">
					<label>Donation Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="membership_name" onChange={this.handleInputChange} className="form-control" value={this.state.membership_name}/>
					<div className="errormembership_name"></div>
				</div>
				
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> :</label>
					{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />  */}
					{/* <CKEditor
					data={this.state.description}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<MyEditor initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>

					<div className="errordescription"></div>
				</div>
			</div>
                  
			<div className="form-right">
			
			
			<div className="form-group">
					<label>Amount Type<span class="required" style={{ color: "red" }} > * </span> : </label> <br></br>
				    <input type="radio" name="period" value="Month" onChange={this.handleInputChange} />
					<label for="Month">Monthly</label>
				    <input type="radio" name="period" value="Year" onChange={this.handleInputChange} />
					<label for="Year">Yearly</label>
					<div className="errorperiod"></div>
				</div><br/>

				<div className="form-group">					
					<label>Donation Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.membership_plan_option} 
                            onChange={this.handleChange.bind(this, 'ins_cate')}
                            />
							<div className="errorplan"></div>
	           </div> <br/>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);