import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      subscriberslist:'',
      domainlist:[],
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"subscribers/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  subscriberslist: res.data.subscriberslist, totalRecord: res.data.records_count}, function() {
            
              this.subscriberslist();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getsubscriberslist(qs.stringify(postobject))
    axios.post(apiUrl+"subscribers/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  subscriberslist: res.data.subscriberslist, totalRecord: res.data.records_count}, function() {
            this.subscriberslist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"subscribers/list",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  subscriberslist: res.data.subscriberslist, totalRecord: res.data.records_count}, function() {
          this.subscriberslist();
        });

    }
  }); 

} 


subscriberslist() {
  let catImg = '';
  let preview = '';
    var subscriberslist = this.state.subscriberslist;
    if (subscriberslist != "undefined" && subscriberslist != null) {
      console.log(subscriberslist);
    
      if (subscriberslist.length > 0) {

        if (Object.keys(subscriberslist).length > 0) {
          const helperlistDetails = subscriberslist.map(
            (subscriberslist, Index) => {
              let sno = Index+1;
              

               if(subscriberslist.current_status == 'A'){
                var activeClass = 'activeClass';
              }else{
                activeClass = 'inactiveClass';
              }

              let newDateDisp = Moment(subscriberslist.created_date).format('DD/MM/YYYY hh:mm A');

              var subscriber_type = subscriberslist.subscriber_type;
             
              return (
                <tr class={activeClass} key={subscriberslist.sub_detail_id}>
      					<td>{sno}</td>
      					<td>{subscriberslist.subscribername}</td>
      					<td>{subscriberslist.email}</td>
      					<td>{subscriberslist.title}</td>                
      					<td>{subscriberslist.membership_name}</td>                
                {/* <td>{subscriber_type.charAt(0).toUpperCase() + subscriber_type.slice(1)}</td> */}
                <td>${subscriberslist.amount + " / " + (subscriberslist.period ? subscriberslist.period + "ly" : "")}</td>
                <td>{newDateDisp}</td>
                <td className="actiongroup" ><Link to={{ pathname: '/subscribers-view/'+subscriberslist.subscriber_id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link></td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="subscribers" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Subscribers List</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Subscriber Name</th>
                  <th>Subscriber Email</th>
                  <th>Subscription Category</th>
                  <th>Subscription Name</th>
                  <th>Amount</th>
                  <th>Subscribed On</th>
                  <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>{this.subscriberslist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (List);