import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import moment from 'moment';
import { apiUrl} from'../Config/Config';
import axios from 'axios';

import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
};

class View extends Component {  
  constructor(props)
  {
    super(props); 
    const paymenthistoryid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      paymenthistoryid: paymenthistoryid,
      paymenthistoryview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :paymenthistoryid
    }; 
    axios.post(apiUrl+"student/paymentview",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
        
        this.setState({paymenthistoryview: res.data.paymenthistoryview[0],paymenthistorylist: res.data.paymenthistoryview_result})
        
      }	
		});
  }

   
   componentDidMount() {
     document.title = PageTitle('Payment View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.paymenthistoryview!==this.state.paymenthistoryview){
        if(Props.paymenthistoryview[0].status == "success"){
          this.setState({paymenthistoryview: Props.paymenthistoryview[0].paymenthistoryview[0],paymenthistorylist: Props.paymenthistoryview[0].paymenthistoryview_result}) 
        }
      }
   }

    paymenthistorylist() {
    var paymenthistorylist = this.state.paymenthistorylist;
    if (paymenthistorylist != "undefined" && paymenthistorylist != null) {
      
        if (Object.keys(paymenthistorylist).length > 0) {
          const helperlistDetails = paymenthistorylist.map(
            (paymenthistorylist, Index) => {
              let sno = Index+1;
              var istyle = { width: 100};
              var payment_plan = paymenthistorylist.payment_type == "onetime" ?  paymenthistorylist.payment_plan = 'N/A' : paymenthistorylist.payment_type;

              var payment_type  = paymenthistorylist.payment_type;

               var username = paymenthistorylist.anonymous_type == "yes" ?  paymenthistorylist.username = 'Anonymous' : paymenthistorylist.username;
            var email = paymenthistorylist.anonymous_type == "yes" ?  paymenthistorylist.email = 'Anonymous' : paymenthistorylist.email;
           var mobileno = paymenthistorylist.anonymous_type == "yes" ?  paymenthistorylist.mobileno = 'Anonymous' : paymenthistorylist.mobileno;

          var name = paymenthistorylist.name == null ?  paymenthistorylist.name = 'N/A' : paymenthistorylist.name;
          var u_name = paymenthistorylist.u_name == null ?  paymenthistorylist.u_name = 'N/A' : paymenthistorylist.u_name;
          var kollel_name = paymenthistorylist.kollel_name == null ?  paymenthistorylist.kollel_name = 'N/A' : paymenthistorylist.kollel_name;
          var kollel_location = paymenthistorylist.kollel_location == null ?  paymenthistorylist.kollel_location = 'N/A' : paymenthistorylist.kollel_location;
          var u_location = paymenthistorylist.u_location == null ?  paymenthistorylist.u_location = 'N/A' : paymenthistorylist.u_location;
          var age = paymenthistorylist.age == null ?  paymenthistorylist.age = 'N/A' : paymenthistorylist.age;
          var paid_status  = paymenthistorylist.paid_status;

          var compaign_name = paymenthistorylist.compaign_name == null ?  paymenthistorylist.compaign_name = 'N/A' : paymenthistorylist.compaign_name;
          var compaign_email = paymenthistorylist.compaign_email == null ?  paymenthistorylist.compaign_email = 'N/A' : paymenthistorylist.compaign_email;
          var compaign_mobileno = paymenthistorylist.compaign_mobileno == null ?  paymenthistorylist.compaign_mobileno = 'N/A' : paymenthistorylist.compaign_mobileno;

          var shules_name = paymenthistorylist.shules_name == null ?  paymenthistorylist.shules_name = 'N/A' : paymenthistorylist.shules_name;
          var shules_email = paymenthistorylist.shules_email == null ?  paymenthistorylist.shules_email = 'N/A' : paymenthistorylist.shules_email;
          var shules_mobileno = paymenthistorylist.shules_mobileno == null ?  paymenthistorylist.shules_mobileno = 'N/A' : paymenthistorylist.shules_mobileno;

          var additional_amount = paymenthistorylist.additional_amount == null ?  paymenthistorylist.additional_amount = 'N/A' : paymenthistorylist.additional_amount;

          if((paymenthistorylist.user_note!='')&&(paymenthistorylist.user_note!=null)){
            var user_note    = paymenthistorylist.user_note;
          }else{
            var user_note    = 'N/A';
          }

          if((paymenthistorylist.payment_type=='recurring')&&(paymenthistorylist.payment_type!=null)){
            var pay    = 'Payment - '+sno;
            
          }else{
            var pay    = 'Payment';
          }

          if(additional_amount !== "N/A"){
            var additional_amount = "$"+additional_amount;
          }else{
            var additional_amount = "N/A";
          }
              return (
                
                <div className="form-row">
                   <div className="form-left">
                   <h3 style={{fontWeight: 'bold'}}>{pay} </h3>
                 <h3 style={{fontWeight: 'bold'}}>Supporter Details</h3>
                 <div className="payment-histroy details_left">
                     <div className="view-details">
                        <div className="view-details-one">
                          <p><b>Supporter<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">   
                          <p>{username}</p>
                          </div>
                          </div>
                          <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Supporter Email<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{email}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Supporter Contact<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{mobileno}</p>
                      </div>
                      </div>
                       {paymenthistorylist.payment_status== 'donate' && <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Payment Type<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{String(payment_type).charAt(0).toUpperCase() + String(payment_type).slice(1)}</p>
                      </div>
                      </div>}
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Supporter Comments<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{String(user_note).charAt(0).toUpperCase() + String(user_note).slice(1)}</p>
                      </div>
                      </div>
                       <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Supported Amount<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>${paymenthistorylist.user_pay_original_amount}</p>
                      </div>
                      </div>
                     {paymenthistorylist.payment_status== 'donate' && <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Company Commission<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>${paymenthistorylist.original_amount}</p>
                      </div>
                      </div>}
                      {paymenthistorylist.payment_status== 'donate' && <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Admin Commission<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>${paymenthistorylist.admin_original_amount}({paymenthistorylist.commission_percentage}%)</p>
                      </div>
                      </div>}
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Supported on<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{moment(paymenthistorylist.created_date).format('MM/DD/YYYY hh:mm A')}</p>
                      </div>
                      </div>
                      </div>
                      </div>

                      <div className="form-right">
                       <h3 style={{fontWeight: 'bold'}}> </h3>
                      <h3 style={{fontWeight: 'bold'}}>{paymenthistorylist.payment_for.charAt(0).toUpperCase() +paymenthistorylist.payment_for.slice(1)} Details</h3> 
                      <div className="payment-histroy details_left">

                      {paymenthistorylist.payment_for=='avrech' && <><div className="view-details">
                        <div className="view-details-one">
                           
                      <p><b>Avrech <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">     
                      <p>{name}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Avrech Age <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{age}</p>
                      </div>
                      </div></>}

                      {paymenthistorylist.payment_for=='kollel' && <><div className="view-details">
                        <div className="view-details-one">
                      <p><b>Kollel <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{kollel_name}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Kollel Location<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{kollel_location}</p>
                      </div>
                      </div>
                      </>}

                      {paymenthistorylist.payment_for=='tutor' && <><div className="view-details">
                        <div className="view-details-one">
                      <p><b>Tutor <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paymenthistorylist.tutor_name}</p>
                      </div>
                      </div>
                      {paymenthistorylist.tutor_location !== null && <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Tutor Location<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paymenthistorylist.tutor_location}</p>
                      </div>
                      </div>}
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Tutor Email<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paymenthistorylist.email}</p>
                      </div>
                      </div>
                      </>}

                      {paymenthistorylist.payment_for=='campaign' && <><div className="view-details">
                        <div className="view-details-one">
                      <p><b>Compaign <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{compaign_name}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Compaign Email <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{compaign_email}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Compaign Mobileno <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{compaign_mobileno}</p>
                      </div>
                      </div></>}

                      {paymenthistorylist.payment_for=='shules' && <><div className="view-details">
                        <div className="view-details-one">
                      <p><b>Shules <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{shules_name}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Shules Email <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{shules_email}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Shules Mobileno <span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{shules_mobileno}</p>
                      </div>
                      </div></>}
                    </div>
                  </div>
                 </div>
              
              );
            }
          );
          return helperlistDetails;
        }
      
    }
  }


  
  render() {

    var payment_plan = this.state.paymenthistoryview.payment_type == "onetime" ?  this.state.paymenthistoryview.payment_plan = 'N/A' : this.state.paymenthistoryview.payment_type;

    var payment_type  = this.state.paymenthistoryview.payment_type;

    var paid_status  = this.state.paymenthistoryview.paid_status;
if((this.state.paymenthistoryview.user_note!='')&&(this.state.paymenthistoryview.user_note!=null)){
  var user_note    = this.state.paymenthistoryview.user_note;
}else{
  var user_note    = 'N/A';
}
   

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="payment-history" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3> Payment History Details</h3>
                </div>
                    {this.paymenthistorylist()}
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    </div>
    );
  }
}

export default withRouter(View);