import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import {apiUrl} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class List extends Component {  
  constructor(props)
  {
    super(props);
    this.state = {
      packagelist:'',
      domainlist:[],
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  

      axios.post(apiUrl+"package/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  packagelist: res.data.packagelist, totalRecord: res.data.records_count}, function() {
            
              this.packagelist();
            });
  
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    axios.post(apiUrl+"package/list",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  packagelist: res.data.packagelist, totalRecord: res.data.records_count}, function() {
            this.packagelist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  axios.post(apiUrl+"package/list",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  packagelist: res.data.packagelist, totalRecord: res.data.records_count}, function() {
          this.packagelist();
        });

    }
  }); 

} 

activepackage(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };
        this.change_status(postObject);
        
      
}

change_status(postObject){
  var qs = require('qs');
  axios.post(apiUrl+"package/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
      window.location.reload(false);
    }
  }); 
}


packagelist() {
  let catImg = '';
  let preview = '';
    var packagelist = this.state.packagelist;
    if (packagelist != "undefined" && packagelist != null) {
    
      if (packagelist.length > 0) {

        if (Object.keys(packagelist).length > 0) {
          const helperlistDetails = packagelist.map(
            (packagelist, Index) => {
              let sno = Index+1;
               if(packagelist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }             
              return (
                <tr key={packagelist.id}>
      					<td>{sno}</td>
      					<td>{packagelist.package}</td>
                <td>${packagelist.price}</td>
      					<td>{(packagelist.payment_type ?<> {packagelist.payment_type!='Onetime'?packagelist.payment_type + "ly":packagelist.payment_type}</>  : "")}</td>
                <td>{status}</td>
                <td className="actiongroup" >
                    
                    
                     <Link to={{ pathname: '/package-view/'+packagelist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                    <Link to={{ pathname: '/package-edit/'+packagelist.id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    {(status == 'Active'?<Link onClick={this.activepackage.bind(this, packagelist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activepackage.bind(this, packagelist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}      

                   

                     {/* <Link onClick={this.deletememebership.bind(this,packagelist.id)} className="" title="Duplicate"><i className="fa fa-trash-2" aria-hidden="true"></i></Link>  */}
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    const {domainlist,universitynamelist} = this.state;
	  var current = this;
    var admin_id = localStorage.getItem('admin_id');
   let catImg = '';
  let preview = '';
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="package" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>package List</h3>
                </div>
               

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                 <div className="add_button">  
                    <a className="" href='/package-add'>Add</a>
                </div>
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>package</th>
                  {/* <th>Description</th> */}
                  <th>Amount</th>
                  <th>Amount Type</th>
                  <th>Status</th>
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.packagelist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default List;