import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { Table } from "react-bootstrap"; 
import Moment from 'moment';

import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
	  />
	);
  };
class View extends Component {  
  constructor(props)
  {
    super(props); 
    const subscriberId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      subscriberId: subscriberId,
      subscriberslistdata:'',
      subscribersingledata:''
    };

    var qs = require('qs');
    var postObject = {             
    id   :subscriberId
    };
    console.log(postObject, 'sub_id');
    axios.post(apiUrl+"subscribers/view",qs.stringify(postObject)).then(res => {
      console.log(res, 'resssssss');
      if(res.data.status === 'success'){
          this.setState({ subscriberslistdata: res.data.subscriberslistview, subscribersingledata: res.data.subscriberslistview[0]});
          console.log(res.data.subscriberslistview[0], 'asfasd')
      }
    }); 
  }

  subscriberData() {
    let catImg = '';
    let preview = '';
      var subscriberslist = this.state.subscriberslistdata;
      if (subscriberslist != "undefined" && subscriberslist != null) {
        console.log(subscriberslist, 'subscriberslist');
      
        if (subscriberslist.length > 0) {
  
          if (Object.keys(subscriberslist).length > 0) {
            const helperlistDetails = subscriberslist.map(
              (subscriberslist, Index) => {
                let sno = Index+1;
                
                var act = "";
                 if(subscriberslist.current_status == 'A'){
                  var activeClass = 'activeClass';
                  act = "(Active)";
                }else{
                  activeClass = 'inactiveClass';
                }
  
                let newDateDisp = Moment(subscriberslist.created_date).format('DD/MM/YYYY hh:mm A');
               
                return (
                  <tr class={activeClass} key={subscriberslist.sub_detail_id}>
                  <td>{sno}</td>
                  <td>{subscriberslist.title + ' ' + act }</td>
                  <td>{subscriberslist.membership_name}</td>
                  <td>${subscriberslist.amount + " / " + (subscriberslist.period ? subscriberslist.period + "ly" : "")}</td>
                  <td>{newDateDisp}</td>
                  </tr>
                );
              }
            );
            return helperlistDetails;
          }
        } else {
          return (
            <tr>
              <td colspan="11" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      }else {
        return (
          <tr>
            <td colspan="11" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }

   componentDidMount() {
     document.title = PageTitle('Subscribers View');
   }

  render() {
  if(this.state.subscribersingledata!==undefined){
    var subscriberslistdata = this.state.subscribersingledata;
    
  }
 
  

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="subscribers" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Subscriber Details</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.subscribername}</p>
                        </div>
                      </div>

                     
                      
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.mobileno != '' && subscriberslistdata.mobileno != null ? subscriberslistdata.mobileno : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.email != '' && subscriberslistdata.email ? subscriberslistdata.email : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Street<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.street_address !='' && subscriberslistdata.street_address != null ? subscriberslistdata.street_address : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>City<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.city != '' && subscriberslistdata.city != null ? subscriberslistdata.city : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>State<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.state !='' && subscriberslistdata.state != null ? subscriberslistdata.state : 'N/A'}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Country<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{subscriberslistdata.country !='' && subscriberslistdata.country != null ? subscriberslistdata.country : 'N/A'}</p>
                        </div>
                      </div>

                      </div>
                      </div>
                      <div className="title">
                      <h3>Subscription History</h3>
                    </div>
                    <div className="overflow">
                      <Table striped bordered hover>
                        <thead>
                        <tr>
                        <th>S.No</th>
                        <th>Subscription Category</th>
                        <th>Subscription Name</th>
                        <th>Amount</th>
                        <th>Subscribed On</th>
                        </tr>
                        </thead>
                        <tbody>{this.subscriberData()}</tbody>
                      </Table>
                    </div>
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);