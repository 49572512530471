import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl,} from'../Config/Config';
import axios from 'axios';
import parse from 'html-react-parser';


import { Link,useParams, useNavigate } from 'react-router-dom';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      cmslist:''
    };

    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };

  
    axios.post(apiUrl+"cms/list",qs.stringify(postobject)).then(res => {
         
      this.setState({ totalRecord : res.data.records_count, cmslist: res.data }, function() {
        this.cmslist();
     });
  });

    

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
      document.title = PageTitle('CMS List');
      if(localStorage.getItem('admin_id') === null){
      
      this.props.navigate('/');
      }
   }

    handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});

      var qs = require('qs');

      var admin_id = localStorage.getItem("admin_id");

      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
      axios.post(apiUrl+"cms/list",qs.stringify(postobject)).then(res => {
         
        this.setState({ totalRecord : res.data.records_count, cmslist: res.data }, function() {
          this.cmslist();
       });
    });
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
           var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
            activePage   : 1,
            search_all : formPayload.search_all,
            admin_id: admin_id         
          };
          axios.post(apiUrl+"cms/list",qs.stringify(postObject)).then(res => {         
            this.setState({ totalRecord : res.data.records_count, cmslist: res.data }, function() {
              this.cmslist();
           });
        });
    } 


   
activateuser(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :id,
      status: stat
    };
    axios.post(apiUrl+"cms/change_status",qs.stringify(postObject)).then(res => {
      if(res.data.status=='success')
      {
        axios.post(apiUrl+"cms/list",qs.stringify(postobject)).then(res => {
            this.setState({ totalRecord : res.data.records_count, cmslist: res.data }, function() {
              this.cmslist();
          });
        });
      }
    });
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id
    };

  
   
}
  cmslist() {

    var cmslist = this.state.cmslist;
    if (cmslist != "undefined" && cmslist != null) {

      if (cmslist.status == "success") {

        if (Object.keys(cmslist).length > 0) {
          const vehiclelistDetails = cmslist.cmslist.map(
            (cmslist, Index) => {
              let sno = Index+1;
              console.log(cmslist.status)
              if(cmslist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }

            var string = '';
            if (cmslist.landing_content!=='' && cmslist.landing_content.length > 25) {
              string = cmslist.landing_content.substring(0, 24) + "...";
            }

			       var istyle = { width: 100};
              return (
                <tr key={cmslist.id}>
                    <td>{sno}</td>
                    <td>{cmslist.name}</td>
                    <td>{parse(string)}</td>
                    <td>{status}</td>
                    <td className="actiongroup">
                      <Link to={{ pathname: '/cms/edit/'+cmslist.id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                       {(status == 'Active'?<Link onClick={this.activateuser.bind(this, cmslist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.activateuser.bind(this, cmslist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}     
                    </td>
                </tr>
              );
            }
          );
          return vehiclelistDetails;
        }
      } else {

        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );

      }
    }

  }
  
  render() {

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="landing_cms" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

              <div className="listing-header">
                <div className="title">
                  <h3>Plugin Content</h3>
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
                {this.state.totalRecord < 3 && 
                  <div className="add_button">  
                      <a className="add" href='/cms-add'>Add</a> 
                  </div>
                }  
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Logo Content</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.cmslist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default withRouter(List);