import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { useParams, useNavigate } from 'react-router-dom';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
            domain_id:'',
			name:'',
			email:'',
			password: '',
			mobileno:'',
			donation_start_date:'',
			donation_end_date:'',
			donation_limit_amount:'',
			status:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selecteddomain:'',
			selecteddomain_value:'',
			shortdescription:'',
			description: '',
			shules_location: '',
			new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		//this.props.getKollelnameList(localStorage.getItem('admin_id'));
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

	handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });
	}

   	handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

    }

	componentDidMount() {
		document.title = PageTitle('Shules Add');
		if(localStorage.getItem('admin_id') === null){
			this.props.navigate('/');
		}
    }

	handleFormSubmit = () => {
		
		if(this.validateForm()){
			console.log('poipoipoi');
			this.setState({Loading:true});
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				name: formPayload.name,
				password : formPayload.password,
				mobileno:formPayload.mobileno,
				// donation_start_date:formPayload.donation_start_date,
				// donation_end_date:formPayload.donation_end_date,
				email:formPayload.email,
				//donation_limit_amount: formPayload.donation_limit_amount,
				short_description:formPayload.shortdescription,
				description:formPayload.description,
				shules_location: formPayload.shules_location,
				from_lat: formPayload.from_lat,
				from_long: formPayload.from_long,
				status:  status,
				domain: 'shiviti.co'
			};
			axios.post(apiUrl+"shules/add",qs.stringify(postObject)).then(res => {
				if(res.data.status === 'success'){
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({							
							Loading:false
						});
						 setTimeout(
						 function(){							
							 $('.success_message').html('');
							 // const navigate = this.props.navigate;
							 this.props.navigate('/shules');
							}
						.bind(this),
						3000
						);
					}else{
					 this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
			});
		}
	}

	validateForm() {
     
		const {name,donation_start_date,donation_end_date,email,mobileno,donation_limit_amount,selecteddomain_value,shortdescription,password,confirm_password,shules_location} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!name) {
			formIsValid = false;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}
		
		if (!mobileno) {
			formIsValid = false;
			$('.errormobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(mobileno){
			$('.errormobileno').html('');
		}

		// if (!donation_start_date) {
		// 	formIsValid = false;
		// 	$('.errordonation_start_date').html('<span class="errorspan">Please fill the field</span>');
		// }else if(donation_start_date){
		// 	$('.errordonation_start_date').html('');
		// }

		// if (!donation_end_date) {
		// 	formIsValid = false;
		// 	$('.errordonation_end_date').html('<span class="errorspan">Please fill the field</span>');
		// }else if(donation_end_date){
		// 	$('.errordonation_end_date').html('');
		// }

		if (!email) {
			formIsValid = false;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}

		// if (!donation_limit_amount) {
		// 	formIsValid = false;
		// 	$('.errordonation_limit_amount').html('<span class="errorspan">Please fill the field</span>');
		// }else if(donation_limit_amount){
		// 	$('.errordonation_limit_amount').html('');
		// }

		if (!selecteddomain_value) {
			formIsValid = false;
			$('.errordomain_value').html('<span class="errorspan">Please fill the field</span>');
		}else if(selecteddomain_value){
			$('.errordomain_value').html('');
		}

		if (!shules_location) {
			formIsValid = false;
			$('.error_location').html('<span class="errorspan">Please fill the field</span>');
		}else if(shules_location){
			$('.error_location').html('');
		}

		if(password){
			if (password.match(/[a-z]/g) && password.match( 
					   /[A-Z]/g) && password.match( 
					   /[0-9]/g) && password.match( 
					   /[^a-zA-Z\d]/g) && password.length >= 8){
							$('.errorpassword').html('');
			}else{
				formIsValid = false;
			   $('.errorpassword').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
			}
		}
   
		// if(confirm_password){
		// 	if (confirm_password.match(/[a-z]/g) && confirm_password.match( 
		// 	   /[A-Z]/g) && confirm_password.match( 
		// 	   /[0-9]/g) && confirm_password.match( 
		// 	   /[^a-zA-Z\d]/g) && confirm_password.length >= 8){
		// 		$('.errorchangepaswd').html('');
		// 	}else{
		// 		formIsValid = false;
		// 	   $('.errorchangepaswd').html('<span class="errorspan">Min 8 characters with special,digit,lower and upper case like Greeks@12</span>');
		// 	}
		// }
		   
		// if(password !== confirm_password){
		// 	formIsValid = false;
		// 	$('.errorchangepaswd').html('<span class="errorspan">Confirm Password does not match</span>');
		// }

		this.setState({
			errors: errors
		});
		console.log(errors, 'errors');
		return formIsValid;
    }

    componentWillReceiveProps(Props){
   		if(Props.shulesadd !== this.props.shulesadd){
    		if(Object.keys(Props.shulesadd).length > 0){
				this.setState({Loading:false});

				if(Props.shulesadd[0].status === "success"){

					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.shulesadd[0].message+'</h3></div>');
				
					setTimeout(
					function() {
						$('.success_message').html(this.props.history.push('/shules'));
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.shulesadd[0].message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					5000
					);
				}
    		}
    	}
    }

	new_pass_show_hide = () => {
		this.setState({
		new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
		pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
		});
	}

	confim_pass_show_hide = () => {
		this.setState({
		confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
		confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
		});
	} 


	onChangeStartDate = (date) => { 

		if (this.state.donation_end_date !== '' && this.state.donation_end_date < date) {
			$('.errordonation_start_date').html('<span class="errorspan">Start date is less than end date</span>');
			$('.errordonation_end_date').html('');
			
			return false;
		}else{
			$('.errordonation_start_date').html('');
			this.setState({ donation_start_date: date })
			
		}
		
	}

	onChangeEndDate = (date) => { 
		if (this.state.donation_start_date > date) {
			$('.errordonation_end_date').html('<span class="errorspan">End date is greater than start date</span>');
			$('.errordonation_start_date').html('');
			return false;
		}else{
			$('.errordonation_end_date').html('');
			this.setState({ donation_end_date: date })
		}
		
	}

	handleLocChange = (event) => {
		const {name, value} = event.target;   
		this.setState({
			[name]: value
		});
		console.log(name, value, 'location');
		const google = window.google;
		const callfunc = this;

		var input = document.getElementById('shules_location');
		var autocomplete = new google.maps.places.Autocomplete(input);
		autocomplete.addListener('place_changed',function() {
		// document.getElementById("location-error").style.display = 'none';
		var place = autocomplete.getPlace();
		var locationAddress = place.formatted_address;
		var fullplace = place.name+', '+locationAddress;

		var res = fullplace.split(",");
		if(res[0] != res[1]){
			//var locationAddress =  place.name+', '+locationAddress;
			var str =  locationAddress;
			var positionExt = str.indexOf(place.name);
			if(positionExt == -1){
			var locationAddress =  place.name+', '+locationAddress;
			}else{
			var locationAddress =  locationAddress;
			}
		}
		callfunc.setState({address: locationAddress})
		callfunc.getgoecoder(locationAddress,'shules_location');
		callfunc.setState({fulldroploc : fullplace});
		});		
   	}

	getgoecoder(input, name){
		$('#shules_location').val('');
		if(name == 'shules_location'){ 
			Geocode.fromAddress(input).then(
			  response => {
				console.log(response,'response')
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					shules_location: response.results[0].formatted_address
				});	
			  },
			  error => {
				console.error(error);
			  }
			);
		}
	}

  	render() {
		const {fundnamelist,selecteddomain, selectedOption} = this.state;
		let domain_list = [{ label: 'shiviti.co', value: 'shiviti.co' }];
		const domainArray = domain_list.map ((domainlist, index) => ({
		id: index,
		name: domainlist,
		}));
		
		// Dynamically create select list
		let domains = [];
		domainArray.map(item =>
			domains.push({ label: item.name.label, value: item.name.value }),
		);
    return (
      	<div className="wrapper"> 
 
	  		<Header />
    		<MenuSidebar currentpage="shules" />  

			<div id="location-error"></div>
			<div className="content">	
				<div className="content-wrapper">
				<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
					<div className="form-wrapper">
						<div className="success_message"></div>
						<div className="title">
							<h4>Add Shules</h4>				
						</div>
						<form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
							<div className="form-row">
								<div className="form-left">

									<div className="form-group">
										<label>Shules Name<span class="required" style={{ color: "red" }} > * </span> : </label>
										<input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
										<div className="errorname"></div>
									</div>

									<div className="form-group">
										<label>Shules Email<span class="required" style={{ color: "red" }} > * </span> : </label>
											<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
										<div className="erroremail"></div>
									</div>
									
									{/* <div className="form-group">					
										<label>Goal Start Date<span class="required" style={{ color: "red" }} > * </span> : </label>
										
											<DatePicker
											selected={this.state.donation_start_date}
											onChange={this.onChangeStartDate}
											dateFormat="MM/dd/yyy"
											showMonthDropdown
											showYearDropdown
											value={this.state.donation_start_date}
											className='form-control'
											/>
										<div className="errordonation_start_date"></div>
										
									</div> */}

									<div className="form-group eye-pad">
										<label>New Password</label>
										<input type={this.state.new_pass_type}  name="password" placeholder="Enter new password" className="form-control" value={this.state.password} onChange={this.handleInputChange.bind(this)} autoComplete="off"/>
										<span toggle="#password-field" className={this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
									</div>

									<div className="form-group">
										<label>Short Description: </label>
											<textarea name="shortdescription" onChange={this.handleInputChange} className="form-control" value={this.state.shortdescription} />
										<div className="error_shortdescription"></div>
									</div>

									<div className="form-group">
										<label>Domain List: </label>
										<Select 
										options={domains}  
										value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
										onChange={this.handleChangeDomain}
										placeholder="Select Domain" />
										<div className="errordomain_value"></div>
									</div>
									
								</div>
							
								<div className="form-right">

									<div className="form-group">					
										<label>Mobile Number<span class="required" style={{ color: "red" }} > * </span> : </label>
											<input type="text" name="mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.mobileno} />
										<div className="errormobileno"></div>
									</div>	

									<div className="form-group">
										<label>Location<span class="required" style={{ color: "red" }} > * </span> : </label>
										<input type="text" className="form-control input-focus" placeholder="" name="shules_location" id="shules_location" value={this.state.shules_location} onChange={this.handleLocChange} autoComplete="off"/>
										<div className="error_location"></div>
									</div>
								
									{/* <div className="form-group">					
										<label>Goal End Date<span class="required" style={{ color: "red" }} > * </span> : </label>
										
											<DatePicker
											selected={this.state.donation_end_date}
											onChange={this.onChangeEndDate}
											dateFormat="MM/dd/yyy"
											showMonthDropdown
											showYearDropdown
											value={this.state.donation_end_date}
											className='form-control'
											/>
										<div className="errordonation_end_date"></div>
										
									</div> */}

									{/* <div className="form-group eye-pad">
										<label>Confirm Password</label>
										<input type={this.state.confrim_pass_type} name="confirm_password" id="confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleInputChange.bind(this)}/>
										<span toggle="#password-field" className={this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="fa fa-eye" aria-hidden="true"></i></span>
									</div>
									<div className="errorchangepaswd"></div>	 */}

									{/* <div className="form-group">
										<label>Goal Limit($)<span class="required" style={{ color: "red" }} > * </span> : </label>
											<input type="text" name="donation_limit_amount" onChange={this.handleInputChange} className="form-control" value={this.state.donation_limit_amount} />
										<div className="errordonation_limit_amount"></div>
									</div> */}

									<div className="form-group">
										<label>Description : </label>
											<textarea name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description} />
									</div>

									<div className="form-group">					
										<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
										<Select 
										value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
											options={lang.common.status_option} 
											onChange={this.handleChange}
											/>
									</div>

								</div>	
							</div>		

							<div className="btn-group export">	
								<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> 
									{this.state.Loading ===true && <span className="load-data"></span> }Submit
								</button>
							</div>
						</form>
					</div>		
				</div>	
			</div>
    	</div>
		</div>
    );
  }
}

export default withRouter(Add);