import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';

import { lang } from '../Helpers/lang';
import axios from 'axios';
import { apiUrl} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
// import CKEditor from 'ckeditor4-react';	
import Select from 'react-select';

import { useParams, useNavigate } from 'react-router-dom';
import MyEditor from './MyEditor';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			package:'',
			description:'',
			amount:'',
			period:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedPlanOption: '',
			selectedvalue:'active',
			selectedPlanvalue:'',
			planLists:'',
			studentlist:{label:'Select Avrech',value:''},
			studentIds:'',
			studentselectedList:[],
			studentselectedValue:[]
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );

    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            description: evt
            // description: evt.editor.getData()
        } );
		}, 1000);
     
    }

	componentDidMount() {
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
		admin_id: admin_id,
		loggedas: loggedas
		};  
  
		axios.post(apiUrl+"package/getstudentlist",qs.stringify(postobject)).then(res => {
		  if(res.data.status === 'success'){
		   
			  this.setState({  studentlist: res.data.studentlist, totalRecord: res.data.records_count});
	
		  }
		}); 
	}	

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'avrech'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
		}
		console.log(selOption.value, 'sdfg')
	};

   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

  }
 
  
  handleSubmit = () => {
	if(this.validateForm()){
	this.setState({Loading:true});
	const formdata = this.state;
	var qs = require('qs');
	var status, cate = '';

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}

	if(formdata.selectedvalue === ''){
	status =formdata.selectedOption.value;
	}else{
	status = formdata.selectedvalue;
	}

		const studentselectedList = formdata.studentselectedList;
		var elements=[];
		let locList  = ''	
		if(studentselectedList!=='' && typeof studentselectedList!==undefined && studentselectedList!==null) {
			locList = studentselectedList.map((number) =>
				elements.push(number.value)				
			);
		}
		// const studentselectedLists=elements.toString();

	var postObject ={
		admin_id: localStorage.getItem('admin_id'),
		package : formdata.package,
		description:formdata.description,
		amount: formdata.amount,
		period: formdata.period,
		status: status,
		studentid:elements.toString(),
	}

	let formData = new FormData();
	for(let k in postObject) {
		formData.append(k, postObject[k]);
	}

	axios.post(apiUrl+"package/add",formData,config).then(res => {
		if(res.data.status === "success"){
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/package')
						}
						.bind(this),
						3000
						);
			}else{
				$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
				
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
				this.setState({Loading:false});
			}
		});
		
	}
  }

  validateForm() {
	const{description,amount, period, selectedPlanOption,studentselectedList} = this.state;

	let errors = {};
	  let formIsValid = true;

	//   if(!package){
	// 	formIsValid = false;
	// 	$('.errorpackage').html('<span class="errorspan">Please fill the field</span>');
	// }else{
		
	// 	$('.errorpackage').html('');
	// }

	if(!description){
		formIsValid = false;
		$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errordescription').html('');
	}
	
	if(!amount){
		formIsValid = false;
		$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.erroramount').html('');
	}

	if(!period){
		formIsValid = false;
		$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorperiod').html('');
	}
	if ((studentselectedList === ''  || studentselectedList ===null || Object.keys(studentselectedList).length === 0)) {
		formIsValid = false;
		$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
	}else{
		
		$('.errorplan').html('');
	}
		
	this.setState({
		errors: errors
	});
	return formIsValid;
}
		
	onSelect=(selectedList)=>{	
		this.setState({studentselectedList : selectedList},()=>{
			console.log(this.state.studentselectedList,'studentselectedList');
		});				
	}

	onRemove = selectedList =>{
		this.setState({studentselectedList:selectedList});	
	}


  render() {
	const {selectedOption,selectedPlanOption,studentIds} = this.state;

	
    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="package" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Add Package</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Amount ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount}/>
					<div className="erroramount"></div>
				</div>
				<div className="form-group">
					<label>Package<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="package" onChange={this.handleInputChange} className="form-control" value={this.state.package}/>
					<div className="errorpackage"></div>
				</div>
				
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						 {/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} />  */}
						{/* <CKEditor
					data={this.state.description}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					<MyEditor initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>

					<div className="errordescription"></div>
				</div>
			</div>
                  
			<div className="form-right">
			
			
			<div className="form-group">
					<label>Amount Type<span class="required" style={{ color: "red" }} > * </span> : </label> <br></br>
				    <input type="radio" name="period" value="Month" onChange={this.handleInputChange} />
					<label for="Month">Monthly</label>
				    <input type="radio" name="period" value="Year" onChange={this.handleInputChange} />
					<label for="Year">Yearly</label>
					<input type="radio" name="period" value="Onetime" onChange={this.handleInputChange} />
					<label for="Year">One time</label>
					<div className="errorperiod"></div>
				</div><br/>

				<div className="form-group">					
					<label>Select Avrechss<span class="required" style={{ color: "red" }} > * </span> :</label>
						    {/* <Select 
						    value={studentIds?studentIds:""}
                            options={this.state.studentlist} 
                            // onChange={this.handleChange.bind(this, 'avrech')}
							onChange={event => this.handleTeamIdsSelect(event)}
							isMulti
                            />  */}
							<Select							
								options={this.state.studentlist} 
								// value={this.state.studentselectedList}
								selectedValues={this.state.studentselectedList} 
								onChange={this.onSelect} // Function will trigger on select event
								isMulti={true}
	                     		// withAll={true}
							/>

							<div className="errorplan"></div>
	           </div> <br/>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
							onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>Adding</span>:<span>Submit</span>
                                        }
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);