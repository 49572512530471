
// export const productURL       = "https://admin.shal.one/qa/media/product";
// export const tabUrl      = "https://admin.shal.one/qa/media/tabs";
// export const adUrl      = "https://admin.shal.one/qa/media/advertisements"; 
// export const mediaUrlImage      = "https://admin.shal.one/qa/media/product";
 /*local */
 
// export const apiUrl      = "http://localhost/shivitinewversion/api2/";
// export const baseUrl      = "http://localhost/shivitinewversion";
// export const carImageUrl      = "http://localhost/shivitinewversion/media/cars";
// export const sampleFileUrl      = "http://localhost/shivitinewversion/media/sample_file/";
// export const licenseURL       = "http://localhost/shivitinewversion/media/driverlicense";
// export const insuranceURL       = "http://localhost/shivitinewversion/media/driverinsurance";
// export const profileURL       = "http://localhost/shivitinewversion/media/driverprofile";
// export const drivercarURL       = "http://localhost/shivitinewversion/media/drivercarimage";
// export const cmscontentURL       = "http://localhost/shivitinewversion/media/cmscontent";
// export const mediaUrl    = "https://admin-va.uxt.design/studentcharity/media/"; 
/*Live */
export const apiUrl           = "https://admin-va.uxt.design/studentcharity/api2/";
export const baseUrl          = "https://admin-va.uxt.design/studentcharity";
export const carImageUrl      = "https://admin-va.uxt.design/studentcharity/media/cars";
export const licenseURL       = "https://admin-va.uxt.design/studentcharity/media/driverlicense";
export const insuranceURL     = "https://admin-va.uxt.design/studentcharity/media/driverinsurance";
export const profileURL       = "https://admin-va.uxt.design/studentcharity/media/driverprofile";
export const drivercarURL     = "https://admin-va.uxt.design/studentcharity/media/drivercarimage";
export const sampleFileUrl    = "https://admin-va.uxt.design/studentcharity/media/sample_file/";
export const cmscontentURL    = "https://admin-va.uxt.design/studentcharity/media/cmscontent"; 
export const mediaUrl    = "https://admin-va.uxt.design/studentcharity/media/"; 



