import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,categoryURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import CKEditor from 'ckeditor4-react';
import MyEditor from './MyEditor';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
	    const membershipId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
            membershipId: membershipId,
			membership_name:'',
			description:'',
			amount:'',
			period:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedPlanOption: "",
			selectedvalue:'',
			selectedPlanvalue:'',
			planLists:''
		};
		// this.props.getCategoryDetailList(membershipId);
		var qs = require('qs');
		var catid = this.state.membershipId;

		axios.get(apiUrl+"donation/listdetail?id="+catid).then(res => {
			this.setState({membership_name:res.data.membershiplist.membership_name,
				description:res.data.membershiplist.description,
				amount:res.data.membershiplist.amount,
				period:res.data.membershiplist.period});
			if(res.data.membershiplist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}

			var postObject = {
				id: res.data.membershiplist.membership_category_id,			
			};

			axios.post(apiUrl+"donation/plandetail",qs.stringify(postObject)).then(res => {
				if(res.data.status == "success"){					
					let plandetail = res.data.plandetail;
					this.setState({selectedPlanOption:{value: plandetail['id'], label: plandetail['title']}});					
				}
			});
			
			
		}); 		
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onEditorChange = this.onEditorChange.bind( this );

    }

	onEditorChange( evt ) {
    	// console.log(evt.editor.getData(), 'CKE')
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            // description: evt.editor.getData()
            description: evt
        } );
		}, 1000);
     
    }

    // handleChange = selectedOption => {

    //     this.setState({selectedOption})
    //     this.setState({  selectedvalue : selectedOption.value});
    // };

	handleChange = (r,selOption) => {
       
		if(r == 'status'){
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
		}else if(r == 'ins_cate'){			
			this.setState({selectedPlanOption:selOption})
			this.setState({  selectedPlanvalue : selOption.value});
		}
 	};
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     		
    }
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file
		  });
		};
		reader.readAsDataURL(file);
	}
	
	handleChangeSpecialist = selectedSpecialist => {

		this.setState({ selectedSpecialist});
		this.setState({ specialistvalue : selectedSpecialist.value });
 }


	componentDidMount() {
		document.title = PageTitle('Donation Edit');
    }

	
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				const formPayload = this.state;
				var qs = require('qs');
				var status, cate = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				if(formPayload.selectedPlanvalue === '' && formPayload.selectedPlanvalue!== 0){
					cate =formPayload.selectedPlanOption.value;
				}else{
					cate = formPayload.selectedPlanvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					membership_name: formPayload.membership_name,
					description:formPayload.description,
					amount: formPayload.amount,
					period: formPayload.period,
					status:  status,
					id: formPayload.membershipId,
					cate_id:cate
				
				};
				// console.log(postObject, 'postOBJ');
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"donation/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/donation')
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
						this.setState({Loading:false});
					}
				});
			}
	}

	validateForm() {
		const {membership_name,description,amount,period,selectedPlanOption} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		  if(!membership_name){
			formIsValid = false;
			$('.errormembership_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errormembership_name').html('');
		}

		if(!description){
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errordescription').html('');
		}

		if(!amount){
			formIsValid = false;
			$('.erroramount').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.erroramount').html('');
		}

		if(!period){
			formIsValid = false;
			$('.errorperiod').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorperiod').html('');
		}

		if(!selectedPlanOption){
			formIsValid = false;
			$('.errorplan').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorplan').html('');
		}
			
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
  render() {


	

  	  const {selectedOption,selectedPlanOption} = this.state;	


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="donation" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div className="title">
				<h4>Donation Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Amount ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="number" name="amount" onChange={this.handleInputChange} className="form-control" value={this.state.amount}/>
					<div className="erroramount"></div>
				</div>
				<div className="form-group">
					<label>Donation Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="membership_name" onChange={this.handleInputChange} className="form-control" value={this.state.membership_name} />
					<div className="errormembership_name"></div>
				</div>
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} > * </span> :</label>
						{/* <textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  /> */}
						{/* <CKEditor
					data={this.state.description}
					//data={templatecontent}
					onChange={this.onEditorChange}
					type="classic"
					/> */}
					 <MyEditor initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>

					<div className="errordescription"></div>
				</div>
			
			</div>
                  
			<div className="form-right">
				
				<div className="form-group">
					<label>Amount Type<span class="required" style={{ color: "red" }} > * </span> : </label> <br></br>
					<label>{(this.state.period ? this.state.period + "ly" : " - " )}</label>
				    {/* <input type="radio" name="period" value="Month" onChange={this.handleInputChange} className="form-control" disabled  checked={(this.state.period == "Month" ? "checked" : "" )}/>
					<label for="Month">Monthly</label>
				    <input type="radio" name="period" value="Year" onChange={this.handleInputChange} className="form-control"  disabled checked={(this.state.period == "Year" ? "checked" : "" )} />
					<label for="Year">Yearly</label>
					<div className="errorperiod"></div> */}
				</div><br/>

				<div className="form-group">					
					<label>Donation Category<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedPlanOption?selectedPlanOption:""}
                            options={lang.common.membership_plan_option} 
                            onChange={this.handleChange.bind(this, 'ins_cate')}
                            />
	           </div> <br/>

               <div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange.bind(this, 'status')}
                            />
	           </div> 

			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Edit);