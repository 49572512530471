import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import moment from 'moment';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
};

class View extends Component {  
  constructor(props)
  {
    super(props); 
    const kollelid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
   
    this.state = {      
      kollelid: kollelid,
      kollelview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :kollelid
    };
    axios.post(apiUrl+"kollel/view",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
        if(res.data.status == "success"){
          this.setState({kollelview:res.data.kollelview[0]}) 
        }
      }	
		}); 
  }

   
   componentDidMount() {
     document.title = PageTitle('student View');
   }

   componentDidUpdate(prevProps,prevState){

   }
 


  
  render() {
    var established_year = moment(this.state.kollelview.established_year).format('MM/DD/YYYY');
    var startdata = moment(this.state.kollelview.k_goal_start_date).format('MM/DD/YYYY');
    var enddate = moment(this.state.kollelview.k_goal_end_date).format('MM/DD/YYYY');
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="kollel" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Kollel Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                    <div className="view-details">
                        <div className="view-details-one">
                         <p><b>Username<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          <p>{this.state.kollelview.username}</p>
                        </div>
                      </div>
                     <div className="view-details">
                        <div className="view-details-one">
                         <p><b>Name <span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                          <p>{this.state.kollelview.kollel_name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Email<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.email}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Mobile Number<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.mobileno}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Website<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.website}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Location<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.kollel_location}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Established Year<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{established_year}</p>
                      </div>
                      </div>
                      {/*<div className="view-details">
                        <div className="view-details-one">
                      <p><b>Plugin Id<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.plugin_id}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Domain Id<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.domain_id}</p>
                      </div>
                      </div>*/}
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Description<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.description}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Goal ($)<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.kollel_goal}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Goal Limit ($)<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollelview.k_goal_limit_amount}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Goal Start Date<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{startdata}</p>
                      </div>
                      </div>

                     <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Goal End Date<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{enddate}</p>
                      </div>
                      </div>
                      
                     
                     
                    </div> 
                    {/* <div className="details_right">
                    <div className="view-details-two">
                    <p>{this.state.kollellistview.u_name}</p>
                    </div>
                    <div className="view-details-two">
                      <p>{this.state.kollellistview.email}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.mobileno}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.website}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.u_location}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.established_year}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.plugin_id}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.domain_id}</p>
                      </div>
                      <div className="view-details-two">
                      <p>{this.state.kollellistview.description}</p>
                      </div>
                      
                      
                    </div>       */}
                  </div> 
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div> 
        </div>       
    </div>
    );
  }
}

export default withRouter(View);