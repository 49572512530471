import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTop } from "../Helpers/SettingHelper";
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { useParams, useNavigate } from 'react-router-dom';
import { apiUrl} from'../Config/Config';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		const kollelid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
	  	
		this.state = {
           // domain_id:'',
			kollelid: kollelid,
			kollel_name:'',			
			kollel_location:'',
			website:'',
			description:'',
			email:'',
			mobileno:'',
			established_year:'',
			established_year_change: '',
			selectedvalue: '',
			selectedvalue: '',
			selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			Loading:false,
			username:'',
			donation:'',
			donation_limit_amount:'',
			donation_start_date: '',
			donation_end_date: '',
			password:'',
			changepassword:'',
			domainlist:[],
			selecteddomain:'',
			selecteddomain_value:''
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		//console.log(this.state.c_company_id+"-test");
		axios.get(apiUrl+"kollel/listdetail?id="+kollelid).then(res => {
			if(res.data.status == 'success'){					
				if(res.data.status === "success"){

					const formdata = res.data.kollellist;
                        
						this.setState({kollel_name:formdata.kollel_name});						
						this.setState({kollel_location: formdata.kollel_location});
						this.setState({from_lat: formdata.kollel_loc_lat});
						this.setState({from_long: formdata.	kollel_loc_long});						
						this.setState({website: formdata.website});
						this.setState({email: formdata.email});
                        this.setState({description: formdata.description});
                        this.setState({mobileno: formdata.mobileno});
						this.setState({established_year: formdata.established_year});
						var start_date = new Date(formdata.k_goal_start_date);
						this.setState({donation_start_date: start_date});
						var end_date = new Date(formdata.k_goal_end_date);
						this.setState({donation_end_date: end_date});
                        this.setState({kollelid: formdata.id});
                        this.setState({username: formdata.username});
                        this.setState({donation: formdata.kollel_goal});
                        this.setState({donation_limit_amount: formdata.k_goal_limit_amount});
						this.setState({established_year_change: new Date()});

							
						if(res.data.selecteddomain!==null && res.data.selecteddomain.length > 0){
							if(res.data.selecteddomain[0].value!== '' && res.data.selecteddomain.value!== null){
								this.setState({ selecteddomain : res.data.selecteddomain[0]})
								}
						}
				
				}					
			}
		});
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });
	 }
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

        const google = window.google;
		const callfunc = this;

		if(name == 'kollel_location'){
			var input = document.getElementById('place_event');
			var autocomplete = new google.maps.places.Autocomplete(input);
			autocomplete.addListener('place_changed',function() {
		//	document.getElementById("location-error").style.display = 'none';
			var place = autocomplete.getPlace();
			var locationAddress1 = place.name;
			var locationAddress = place.formatted_address;
			var fullplace = place.name+','+locationAddress;
			callfunc.setState({kollel_location: locationAddress})
			callfunc.getgoecoder(locationAddress,input.name);
			callfunc.setState({fulldroploc : fullplace});
			});
	}

	 if(name == 'password'){
      	this.setState({disablepasssword: false})
      }

    }

    getgoecoder(input, name){

		$('#place_event').val('');
			
		if(name == 'kollel_location'){ 
			Geocode.fromAddress(input).then(
			  response => {
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng,
					 from_lat: lat,
					 kollel_location: response.results[0].formatted_address
				});	
				
				document.getElementById('place_event').value = response.results[0].formatted_address;
				$('#place_event').val(response.results[0].formatted_address);				  
			
			  },
			  error => {
				console.error(error);
			  }
			);
		}

	}

	keyPressed(event) {
		if (event.key === "Enter") {
		localStorage.setItem('setkeyword',event.target.value);
		 setTimeout(() => {
			   $('#clickToggle').trigger('click');
			   document.getElementById("from_loc").value = "";
			}, 500);
	   }
	   }


	componentDidMount() {
		document.title = PageTitle('Kollel Edit');
		var admin_id = localStorage.getItem('admin_id');
		var loggedas = localStorage.getItem('loggedas');
		axios.get(apiUrl+'student/getdomainlist?admin_id='+admin_id+'&loggedas='+loggedas)			
		.then(res => {
			if(res.data.status == 'success'){
				console.log(res.data);				
				this.setState({
					domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist)
				})				
			}else{
			//console.log("test")
			}
		});
    }


    handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
			status =formPayload.selectedOption.value;
			}else{
			status = formPayload.selectedvalue;
			}
			var postObject = {
				admin_id : localStorage.getItem("admin_id"),
				kollelid       : formPayload.kollelid,
				// domain_id : formPayload.domain_id,
				kollel_name: formPayload.kollel_name,
				kollel_location: formPayload.kollel_location,
				from_lat:formPayload.from_lat,
				from_long:formPayload.from_long,					
				website:formPayload.website,
				description: formPayload.description,
				mobileno:formPayload.mobileno,
				email:formPayload.email,
				established_year:formPayload.established_year,
				established_year_change:formPayload.established_year_change,
				goal:  formPayload.donation,
				goal_limit_amount:  formPayload.donation_limit_amount,
				goal_start_date:formPayload.donation_start_date,
				goal_end_date:formPayload.donation_end_date,
				currentpswd         : formPayload.password,
				changepassword      : formPayload.changepassword,
				domain:formPayload.selecteddomain_value
			};


			axios.post(apiUrl+"kollel/edit",qs.stringify(postObject)).then(res => {
				if(res.data.status === 'success'){

					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');						
					
					const formdata = res.data.kollellist;
					this.setState({kollel_name:formdata.kollel_name});						
					this.setState({kollel_location: formdata.kollel_location});
					this.setState({from_lat: formdata.kollel_loc_lat});
					this.setState({from_long: formdata.	kollel_loc_long});						
					this.setState({website: formdata.website});
					this.setState({email: formdata.email});
					this.setState({description: formdata.description});
					this.setState({mobileno: formdata.mobileno});
					this.setState({established_year: formdata.established_year});
					var start_date = new Date(formdata.k_goal_start_date);
					this.setState({donation_start_date: start_date});
					var end_date = new Date(formdata.k_goal_end_date);
					this.setState({donation_end_date: end_date});
					this.setState({kollelid: formdata.id});
					this.setState({username: formdata.username});
					this.setState({donation: formdata.kollel_goal});
					this.setState({donation_limit_amount: formdata.k_goal_limit_amount});
					this.setState({established_year_change: new Date()});

						
					if(res.data.selecteddomain!==null && res.data.selecteddomain.length > 0){
						if(res.data.selecteddomain[0].value!== '' && res.data.selecteddomain.value!== null){
							this.setState({ selecteddomain : res.data.selecteddomain[0]})
							}
					}

					setTimeout(
					function(){							
						$('.success_message').html('');
						// const navigate = this.props.navigate;
						this.props.navigate('/kollel');
						}
					.bind(this),
					3000
					);
				}else{
				this.setState({Loading:false});
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});


		}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {kollel_name,kollel_location,website,email,description,established_year,mobileno,password,changepassword,selecteddomain} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		if (!kollel_name) {
			formIsValid = false;
			$('.errorkollel_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(kollel_name){
			$('.errorkollel_name').html('');
		}	

		if (!kollel_location) {
			formIsValid = false;
			$('.errorkollel_location').html('<span class="errorspan">Please fill the field</span>');
		}else if(kollel_location){
			$('.errorkollel_location').html('');
		}				


        if (!mobileno) {
			formIsValid = false;
			$('.errormobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(mobileno){
			$('.errormobileno').html('');
		}

		if (!email) {
			formIsValid = false;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}

		if(password){
			if(!changepassword){
				formIsValid = false;
			   $('.errorchangepaswd').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorchangepaswd').html('');
			}
		}else{
			$('.errorchangepaswd').html('');
		}

      /*  if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}

		if (!established_year) {
			formIsValid = false;
			$('.errorestablished_year').html('<span class="errorspan">Please fill the field</span>');
		}else if(established_year){
			$('.errorestablished_year').html('');
		}*/
		if (!selecteddomain) {
			formIsValid = false;
			$('.errordomain').html('<span class="errorspan">Please fill the field</span>');
		}else if(selecteddomain){
			$('.errordomain').html('');
		}

		this.setState({
			errors: errors
		});
		return formIsValid;
    }


	onChangedatetime1 = (date) => {
		this.setState({ established_year_change: date })
		this.setState({ established_year: date })
	}
	onChangegoalenddate = (date) => { 

	 this.setState({ donation_end_date: date })

	}

	onChangegoalstartdate = (date) => { 

	 this.setState({ donation_start_date: date })

    }
  render() {
	const domainArray = this.state.domainlist.map ((domainlist, index) => ({
		id: index,
		name: domainlist,
		}));
		
		// Dynamically create select list
		let domains = [];
		domainArray.map(item =>
			domains.push({ label: item.name.label, value: item.name.value }),
		);

  	  const {selectedOption,donation_start_date,donation_end_date,selecteddomain} = this.state;
		if(this.state.established_year!== null){

		}
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="kolleledit" />  

	
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Kollel Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
            <div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="kollel_name" onChange={this.handleInputChange} className="form-control" value={this.state.kollel_name} />
					<div className="errorkollel_name"></div>
				</div>

				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>	

				<div className="form-group">
					<label>Established Year:</label>
				
					    <DatePicker
						 selected={this.state.established_year_change}
						 onChange={this.onChangedatetime1}
						 dateFormat="yyy-MM-dd"
						 showMonthDropdown
                        showYearDropdown
						 maxDate={new Date()}
						 value={this.state.established_year !=null ? moment(this.state.established_year).format('L') : ''}
					  /> 
				   <div className="errorestablished_year"></div>
				   
				</div>	
				 <div className="form-group">
					<label>Goal ($)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="donation" onChange={this.handleInputChange} className="form-control" value={this.state.donation} placeholder="Enter Goal ($)"/>
					<div className="errordonation"></div>
				</div>
				
				<div className="form-group">					
					<label>Goal Start Date<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
				        selected={this.state.donation_start_date}
				        onChange={this.onChangegoalstartdate}
				        dateFormat="MM/dd/yyy"
				        showMonthDropdown
                        showYearDropdown
						value={this.state.donation_start_date!='' ? this.state.donation_start_date : ''}
				        />
					<div className="errordonation_start_date"></div>
					
				</div>

				{localStorage.getItem('admin_id') !== '1' ?
				<div className="form-group">
					<label>Current password:</label>
				    <input type="password" name="password" onChange={this.handleInputChange} className="form-control" value={this.state.password} />
					<div className="errorpassword"></div>
				</div>
					: ''
				}

				<div className="form-group">
					<label>Location<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" id="place_event" placeholder="Enter a  location" name="kollel_location" onChange={this.handleInputChange} className="form-control" value={this.state.kollel_location} />
					<div className="errorkollel_location"></div>
				</div>	
				<div className="form-group">					
					<label>Domain List : </label>
						<Select 
                         options={domains}  
                         value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
                         onChange={this.handleChangeDomain}
                         placeholder="Select Domain" />
						 <div className="errordomain"></div>
				</div>		

			</div>
			<div className="form-right">

				<div className="form-group">
					<label>Username<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} autoComplete="off" readOnly='true' />
					<div className="errorusername"></div>
				</div>
				<div className="form-group">					
					<label>Mobile Number<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.mobileno} />
					<div className="errormobileno"></div>
				</div>

				<div className="form-group">
					<label>Website : </label>
				    <input type="text" name="website" onChange={this.handleInputChange} className="form-control" value={this.state.website} />
					<div className="errorwebsite"></div>
				</div>
				
					
				<div className="form-group">
					<label>Goal Limit($)<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="donation_limit_amount" onChange={this.handleInputChange} className="form-control" value={this.state.donation_limit_amount} />
					<div className="errordonation_limit_amount"></div>
				</div>
				<div className="form-group">					
					<label>Goal End Date<span class="required" style={{ color: "red" }} > * </span> : </label>
					
					    <DatePicker
				        selected={this.state.donation_end_date}
				        onChange={this.onChangegoalenddate}
				       dateFormat="dd/MM/yyyy"
				       showMonthDropdown
                        showYearDropdown
						value={this.state.donation_end_date!='' ? this.state.donation_end_date : ''}
				        />
					<div className="errordonation_end_date"></div>
					
				</div>
				<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.changepassword} disabled={this.state.disablepasssword}/>
					<div className="errorchangepaswd"></div>
				</div>
				<div className="form-group">					
					<label>Description:</label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description} />
					<div className="errordescription"></div>
				</div>
			</div>	
			</div>		

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	</div>
    </div>
    );
  }
}

export default withRouter(Edit);