import React, { Component } from 'react';

import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {  
  constructor(props)
  {
    super(props); 
    const studentid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
    
    // const studentid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      studentid: studentid,
      studentlistview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :studentid
    };
    axios.post(apiUrl+"student/view",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
        
          this.setState({studentlistview:res.data.studentlistview[0]}) 
        
      }	
		});
    
  }

   
   componentDidMount() {
     document.title = PageTitle('student View');
   }

   componentDidUpdate(prevProps,prevState){

   }
  //  componentWillReceiveProps(Props){
  //     if(Props.studentlistview!==this.state.studentlistview){
  //       if(Props.studentlistview[0].status == "success"){
  //         this.setState({studentlistview: Props.studentlistview[0].studentlistview[0]}) 
  //       }
  //     }
  //  }


  
  render() {

    if((this.state.studentlistview.u_name !== '') && (this.state.studentlistview.u_name !== null)){
                var u_name = this.state.studentlistview.u_name;
              }else{
                  u_name = 'N/A';
              }

              if((this.state.studentlistview.f_name != '') && (this.state.studentlistview.f_name != null)){
                var f_name = this.state.studentlistview.f_name;
              }else{
                  f_name = 'N/A';
              }

              if(this.state.studentlistview.kollel_name !== '' && this.state.studentlistview.kollel_name !== null){
                var kollel_name = this.state.studentlistview.kollel_name;
              }else{
                  kollel_name = 'N/A';
              }

              if((this.state.studentlistview.about_student_story !== '') && (this.state.studentlistview.about_student_story !== null)){
                var about_student_story = this.state.studentlistview.about_student_story;
              }else{
                  about_student_story = 'N/A';
              }

              if((this.state.studentlistview.amount !== '') && (this.state.studentlistview.amount !== null)){
                var amount = this.state.studentlistview.amount;
              }else{
                  amount = 'N/A';
              }


    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="student" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3>Avrech View</h3>
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>UserName<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.username}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Age<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.age}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.email}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Mobile Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.mobileno}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Parent Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.parent_name}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Parent Mobile Number<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.mobileno}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>University Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{u_name}</p>
                        </div>
                      </div>
                      {<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Kollel Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{kollel_name}</p>
                        </div>
                      </div>}
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Donation Amount<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{amount}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Fund Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{f_name}</p>
                        </div>
                      </div>
                      {/*<div className="view-details">
                        <div className="view-details-one">
                            <p><b>Plugin Id<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.plugin_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Domain Id<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.domain_id}</p>
                        </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Address<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{this.state.studentlistview.address}</p>
                        </div>
                      </div> */}
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>About Story<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{about_student_story}</p>
                        </div>
                      </div>
                  </div>
                </div>
                    
                  

                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    </div>
    );
  }
}


export default withRouter(View);