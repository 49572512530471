import React, { Component } from 'react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth,scrollToTop } from "../Helpers/SettingHelper";
import Geocode from "react-geocode";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { apiUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';

import { useParams, useNavigate } from 'react-router-dom';


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);
		const compaignId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
	  	this.state = {
			compaignId: compaignId,
			name:'',
			email:'',
			mobileno:'',
			donation_start_date:'',
			donation_end_date:'',
			amount:'',
			donation_limit_amount:'',
			status:'',
			selectedvalue: '',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			Loading:false,
			domainlist:[],
			shuleslist: [],
			compaigndetails: '',
			selecteddomain:'',
			selecteddomain_value:'',
			selectedshules: '',
			selectedshules_value: '',
			shortdescription:'',
			description:'',
			compaign_location: '',
			shules_id: '',
			shules_name: ''
		};
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.handleChangeDomain = this.handleChangeDomain.bind(this);
		Geocode.setApiKey("AIzaSyBHmcBwYlcEtbcBeW3NaZtZw9Yuqtepmuc");
		axios.get(apiUrl+"compaign/listdetail?id="+compaignId).then(res => {
						
			if(res.data.status === "success"){
				const formdata = res.data.compaignlist;

				if(formdata.status === 'A'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
				} 

				this.setState({name:formdata.compaign_name});
				this.setState({email: formdata.email});
				this.setState({mobileno: formdata.mobileno});
				this.setState({donation_limit_amount: formdata.goal_amount});
				this.setState({compaignId: formdata.id});
				this.setState({shortdescription: formdata.short_description});
				this.setState({description: formdata.description});
				this.setState({compaign_location: formdata.compaign_location});
				this.setState({shules_name: formdata.shules_name});
				this.setState({selectedshules:{value: formdata.shules_id, label: formdata.shules_name}});

				if(formdata.donation_start_date !== null){
					var start_date = new Date(formdata.start_date);
					this.setState({donation_start_date: start_date});
				}else {
					this.setState({donation_start_date: new Date()});
				}

				if(formdata.donation_end_date !== null){
					var end_date = new Date(formdata.end_date);
					this.setState({donation_end_date: end_date});
				}else {
					this.setState({donation_end_date: new Date()});
				}
				
				
				if(res.data.selecteddomain!==null && res.data.selecteddomain.length > 0){
					if(res.data.selecteddomain[0].value!== '' && res.data.selecteddomain.value!== null){
						this.setState({ selecteddomain : res.data.selecteddomain[0], selecteddomain_value : res.data.selecteddomain[0].value})
						}
				}

				if(res.data.selecteddomain!==null && res.data.selecteddomain.length > 0){
					if(res.data.selecteddomain[0].value!== '' && res.data.selecteddomain.value!== null){
						this.setState({ selecteddomain : res.data.selecteddomain[0], selecteddomain_value : res.data.selecteddomain[0].value})
						}
				}

				if(res.data.selectedshules!==null && res.data.selectedshules.length > 0){
					if(res.data.selectedshules[0].value!== '' && res.data.selectedshules.value!== null){
						this.setState({ shuleslist : res.data.selectedshules})
						}
				}
			}					
			
		});
    }

	handleChangeDomain =  selecteddomain => {
		this.setState({ selecteddomain});
		this.setState({ selecteddomain_value : selecteddomain.value });
	}

	handleChangeShule = selectedshules => {
		this.setState({selectedshules});
		this.setState({ shules_id: selectedshules.value, shules_name: selectedshules.label });
	}

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

	handleInputChange = (event) => {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
		const google = window.google;
		const callfunc = this;
    }

	componentDidMount() {
		document.title = PageTitle('Compaign Edit');

		var admin_id = localStorage.getItem('admin_id');
		var loggedas = localStorage.getItem('loggedas');
		axios.get(apiUrl+'compaign/getdomainlist?admin_id='+admin_id+'&loggedas='+loggedas)			
		.then(res => {
			if(res.data.status == 'success'){
				console.log(res.data);				
				this.setState({
					domainlist: [{value: '', label: 'Select Your Domain'}].concat(res.data.domainlist),
					//shuleslist: [{value: '', label: 'Select Your Shules'}].concat(res.data.shuleslist),
				})				
			}else{
			//console.log("test")
			}
		});
    }

    handleFormSubmit = () => {
		if(this.validateForm()){ 
			this.setState({Loading:true});
			const formPayload = this.state;
			var qs = require('qs');
			var status = '';
			if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
			}else{
				status = formPayload.selectedvalue;
			}

			var postObject = {
				compaign_id: formPayload.compaignId,
				admin_id : localStorage.getItem("admin_id"),
				name: formPayload.name,
				mobileno:formPayload.mobileno,
				donation_start_date:formPayload.donation_start_date,
				donation_end_date:formPayload.donation_end_date,
				email:formPayload.email,
				donation_limit_amount: formPayload.donation_limit_amount,
				short_description:formPayload.shortdescription,
				description:formPayload.description,
				compaign_location: formPayload.compaign_location,
				from_lat: formPayload.from_lat,
				from_long: formPayload.from_long,
				status:  status,
				domain: 'shiviti.co',
				shules_id: formPayload.shules_id,
				shules_name: formPayload.shules_name
			};
			axios.post(apiUrl+"compaign/edit",qs.stringify(postObject)).then(res => {
				if(res.data.status === 'success'){
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');		
					setTimeout(
					function(){							
						$('.success_message').html('');
						// const navigate = this.props.navigate;
						this.props.navigate('/compaign');
						}
					.bind(this),
					3000
					);
				}else{
					this.setState({Loading:false});
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
			
		}
	}

	validateForm() {
     
		const {name,donation_start_date,donation_end_date,email,mobileno,donation_limit_amount,selecteddomain_value,shortdescription,compaign_location} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		if (!name) {
			formIsValid = false;
			$('.errorname').html('<span class="errorspan">Please fill the field</span>');
		}else if(name){
			$('.errorname').html('');
		}
		
		if (!mobileno) {
			formIsValid = false;
			$('.errormobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(mobileno){
			$('.errormobileno').html('');
		}

		if (!donation_start_date) {
			formIsValid = false;
			$('.errordonation_start_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_start_date){
			$('.errordonation_start_date').html('');
		}

		if (!donation_end_date) {
			formIsValid = false;
			$('.errordonation_end_date').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_end_date){
			$('.errordonation_end_date').html('');
		}

		if (!email) {
			formIsValid = false;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}

		if (!donation_limit_amount) {
			formIsValid = false;
			$('.errordonation_limit_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(donation_limit_amount){
			$('.errordonation_limit_amount').html('');
		}

		if (!selecteddomain_value) {
			formIsValid = false;
			$('.errordomain_value').html('<span class="errorspan">Please fill the field</span>');
		}else if(selecteddomain_value){
			$('.errordomain_value').html('');
		}

		if (!compaign_location) {
			formIsValid = false;
			$('.error_location').html('<span class="errorspan">Please fill the field</span>');
		}else if(compaign_location){
			$('.error_location').html('');
		}

		// if (!shortdescription) {
		// 	formIsValid = false;
		// 	$('.error_shortdescription').html('<span class="errorspan">Please fill the field</span>');
		// }else if(shortdescription){
		// 	$('.error_shortdescription').html('');
		// }

		this.setState({
			errors: errors
		});
		console.log(errors, 'errors');
		return formIsValid;
    }

    // componentWillReceiveProps(Props){
	// 	console.log(Props,"props");

	// 	if(Props.compaigndetails !== this.props.compaigndetails){
    // 		if(Object.keys(Props.compaigndetails).length > 0){
	// 			this.setState({Loading:false});

	// 			if(res.data.status === "success"){

	// 				const formdata = res.data.compaignlist;

	// 				if(formdata.status === 'A'){
	// 					this.setState({selectedOption:{value: 'active', label: 'Active'}});
	// 				}else{
	// 					this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
	// 				} 

	// 				this.setState({name:formdata.compaign_name});
	// 				this.setState({email: formdata.email});
	// 				this.setState({mobileno: formdata.mobileno});
	// 				this.setState({donation_limit_amount: formdata.goal_amount});
	// 				this.setState({compaignId: formdata.id});
	// 				this.setState({shortdescription: formdata.short_description});
	// 				this.setState({description: formdata.description});
	// 				this.setState({compaign_location: formdata.compaign_location});
	// 				this.setState({shules_name: formdata.shules_name});
	// 				this.setState({selectedshules:{value: formdata.shules_id, label: formdata.shules_name}});

	// 				if(formdata.donation_start_date !== null){
	// 					var start_date = new Date(formdata.start_date);
	// 					this.setState({donation_start_date: start_date});
	// 				}else {
	// 					this.setState({donation_start_date: new Date()});
	// 				}

	// 				if(formdata.donation_end_date !== null){
	// 					var end_date = new Date(formdata.end_date);
	// 					this.setState({donation_end_date: end_date});
	// 				}else {
	// 					this.setState({donation_end_date: new Date()});
	// 				}
					
					
	// 				if(Props.compaigndetails[0].selecteddomain!==null && Props.compaigndetails[0].selecteddomain.length > 0){
	// 					if(Props.compaigndetails[0].selecteddomain[0].value!== '' && Props.compaigndetails[0].selecteddomain.value!== null){
	// 						this.setState({ selecteddomain : Props.compaigndetails[0].selecteddomain[0], selecteddomain_value : Props.compaigndetails[0].selecteddomain[0].value})
	// 						}
	// 				}

	// 				if(Props.compaigndetails[0].selecteddomain!==null && Props.compaigndetails[0].selecteddomain.length > 0){
	// 					if(Props.compaigndetails[0].selecteddomain[0].value!== '' && Props.compaigndetails[0].selecteddomain.value!== null){
	// 						this.setState({ selecteddomain : Props.compaigndetails[0].selecteddomain[0], selecteddomain_value : Props.compaigndetails[0].selecteddomain[0].value})
	// 						}
	// 				}

	// 				if(Props.compaigndetails[0].selectedshules!==null && Props.compaigndetails[0].selectedshules.length > 0){
	// 					if(Props.compaigndetails[0].selectedshules[0].value!== '' && Props.compaigndetails[0].selectedshules.value!== null){
	// 						this.setState({ shuleslist : Props.compaigndetails[0].selectedshules})
	// 						}
	// 				}
					
	// 			}else{
	// 				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.compaigndetails[0].message+'</h3></div>');
	// 				setTimeout(
	// 					function() {
	// 						$('.success_message').html();
	// 					}
	// 					.bind(this),
	// 					5000
	// 				);
	// 			}
    // 		}
    // 	}

    // 	if(Props.compaignedit !== this.props.compaignedit){
    // 		if(Object.keys(Props.compaignedit).length > 0){
	// 			if(Props.compaignedit[0].status === "success"){
	// 				console.log(Props.compaignedit,"updated");
    // 					this.setState({ Loading: false });

    // 			const formpayload = Props.compaignedit[0].compaignlist;

	// 			if(formpayload.status === 'A'){
	// 				this.setState({selectedOption:{value: 'active', label: 'Active'}});
	// 			}else{
	// 				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

	// 			}

	// 			this.setState({name:formpayload.compaign_name});	
    //             this.setState({mobileno: formpayload.mobileno});
	// 			this.setState({email: formpayload.email});
	// 			this.setState({donation_limit_amount: formpayload.goal_amount});
    //             this.setState({compaignId: formpayload.id});
	// 			this.setState({shortdescription: formpayload.shortdescription});
	// 			this.setState({description: formpayload.description});
	// 			this.setState({compaign_location: formpayload.compaign_location});

	// 			if(formpayload.donation_start_date !== null){
	// 				var start_date = new Date(formpayload.donation_start_date);
	// 				this.setState({donation_start_date: start_date});
	// 			}else {
	// 				this.setState({donation_start_date: new Date()});
	// 			}

	// 			if(formpayload.donation_end_date !== null){
	// 				var end_date = new Date(formpayload.donation_end_date);
	// 				this.setState({donation_end_date: end_date});
	// 			}else{
	// 				this.setState({donation_end_date: new Date()});
	// 			}
				
    //            	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.compaignedit[0].message+'</h3></div>');
    //            			//scrollToTop();
    // 					setTimeout(
	// 					function() { 
	// 						$('.success_message').html(this.props.history.push('/compaign'));
	// 					}.bind(this), 3000);
						
    // 		}else{
	// 				this.setState({ Loading: false });
	// 				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.compaignedit[0].message+'</h3></div>');
	// 				//scrollToTop();
    // 					setTimeout(
	// 					function() {
	// 						$('.success_message').html('');
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
	// 		}
	// 		}
    // 	}
		
    // }

	onChangeStartDate = (date) => { 

		if (this.state.donation_end_date !== '' && this.state.donation_end_date < date) {
			$('.errordonation_start_date').html('<span class="errorspan">Start date is less than end date</span>');
			$('.errordonation_end_date').html('');
			
			return false;
		}else{
			$('.errordonation_start_date').html('');
			this.setState({ donation_start_date: date })
			
		}
		
	}

	onChangeEndDate = (date) => { 
		if (this.state.donation_start_date > date) {
			$('.errordonation_end_date').html('<span class="errorspan">End date is greater than start date</span>');
			$('.errordonation_start_date').html('');
			return false;
		}else{
			$('.errordonation_end_date').html('');
			this.setState({ donation_end_date: date })
		}
		
	}

	handleLocChange = (event) => {
		const {name, value} = event.target;   
		this.setState({
			[name]: value
		});
		console.log(name, value, 'location');
		const google = window.google;
		const callfunc = this;

		var input = document.getElementById('compaign_location');
		var autocomplete = new google.maps.places.Autocomplete(input);
		autocomplete.addListener('place_changed',function() {
		// document.getElementById("location-error").style.display = 'none';
		var place = autocomplete.getPlace();
		var locationAddress = place.formatted_address;
		var fullplace = place.name+', '+locationAddress;
		console.log(locationAddress,"locationAddress");
		console.log(fullplace,"fullplace");
		
		var res = fullplace.split(",");
		if(res[0] != res[1]){
			//var locationAddress =  place.name+', '+locationAddress;
			var str =  locationAddress;
			var positionExt = str.indexOf(place.name);
			if(positionExt == -1){
			var locationAddress =  place.name+', '+locationAddress;
			}else{
			var locationAddress =  locationAddress;
			}
		}
		callfunc.setState({address: locationAddress})
		callfunc.getgoecoder(locationAddress,'compaign_location');
		callfunc.setState({fulldroploc : fullplace});
		});		
   	}

	getgoecoder(input, name){
		//$('#compaign_location').val('');
		if(name == 'compaign_location'){ 
			Geocode.fromAddress(input).then(
			  response => {
				console.log(response,'response')
				const { lat, lng } = response.results[0].geometry.location;				
				this.setState({
					from_long : lng, 
					from_lat: lat,
					compaign_location: response.results[0].formatted_address
				});	
			  },
			  error => {
				console.error(error);
			  }
			);
		}
	}
	
  render() {

	const {selecteddomain, selectedshules, shuleslist, selectedOption} = this.state;
	let domain_list = [{ label: 'shiviti.co', value: 'shiviti.co' }];
	const domainArray = domain_list.map ((domainlist, index) => ({
	id: index,
	name: domainlist,
	}));
	
	let shules_list = shuleslist;
	const shulesArray = shules_list.map ((shulelist, index) => ({
		id: index,
		name: shulelist,
	}));

	let domains = [];
	domainArray.map(item =>
		domains.push({ label: item.name.label, value: item.name.value }),
	);

	let shules = [];
	shulesArray.map(item =>
		shules.push({ label: item.name.label, value: item.name.value }),
	);

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="compaignedit" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Campaign Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
				<div className="form-left">

					<div className="form-group">
						<label>Campaign Name<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name} />
						<div className="errorname"></div>
					</div>

					<div className="form-group">
						<label>Campaign Email<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
						<div className="erroremail"></div>
					</div>

					<div className="form-group">					
						<label>Goal Start Date<span class="required" style={{ color: "red" }} > * </span> : </label>
						
							<DatePicker
							selected={this.state.donation_start_date}
							onChange={this.onChangeStartDate}
							dateFormat="MM/dd/yyy"
							showMonthDropdown
							showYearDropdown
							value={this.state.donation_start_date}
							className='form-control'
							/>
						<div className="errordonation_start_date"></div>
						
					</div>

					<div className="form-group">
						<label>Short Description: </label>
							<textarea name="shortdescription" onChange={this.handleInputChange} className="form-control" value={this.state.shortdescription} />
						<div className="error_shortdescription"></div>
					</div>

					<div className="form-group">
						<label>Shules: </label>
						<input type="hidden" name="shules_name" className="form-control" value={this.state.shules_name} />
						<Select 
						options={shules}  
						value={selectedshules?selectedshules:{ value: '0', label: 'Select Shule' }}
						onChange={this.handleChangeShule}
						placeholder="Select Shule" />
						<div className="errorshules_value"></div>
					</div>

					<div className="form-group">
						<label>Domain List: </label>
						<Select 
						options={domains}  
						value={selecteddomain?selecteddomain:{ value: '0', label: 'Select Domain' }}
						onChange={this.handleChangeDomain}
						placeholder="Select Domain" />
						<div className="errordomain"></div>

						<div className="errordomain_value"></div>
					</div>

				</div>

				<div className="form-right">

					<div className="form-group">					
						<label>Mobile Number<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="mobileno" onChange={this.handleInputChange} className="form-control" value={this.state.mobileno} />
						<div className="errormobileno"></div>
					</div>	

					<div className="form-group">
						<label>Location<span class="required" style={{ color: "red" }} > * </span> : </label>
						<input type="text" className="form-control input-focus" placeholder="" name="compaign_location" id="compaign_location" value={this.state.compaign_location} onChange={this.handleLocChange} autoComplete="off"/>
						<div className="error_location"></div>
					</div>
				
					<div className="form-group">					
						<label>Goal End Date<span class="required" style={{ color: "red" }} > * </span> : </label>
						
							<DatePicker
							selected={this.state.donation_end_date}
							onChange={this.onChangeEndDate}
							dateFormat="MM/dd/yyy"
							showMonthDropdown
							showYearDropdown
							value={this.state.donation_end_date}
							className='form-control'
							/>
						<div className="errordonation_end_date"></div>
						
					</div>

					<div className="form-group">
						<label>Goal Limit($)<span class="required" style={{ color: "red" }} > * </span> : </label>
							<input type="text" name="donation_limit_amount" onChange={this.handleInputChange} className="form-control" value={this.state.donation_limit_amount} />
						<div className="errordonation_limit_amount"></div>
					</div>

					<div className="form-group">
						<label>Description : </label>
							<textarea name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description} />
					</div>

					<div className="form-group">					
						<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						<Select 
						value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							onChange={this.handleChange}
							/>
					</div>

				</div>	
			</div>	

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
	</div>
    );
  }
}
export default withRouter(Edit);